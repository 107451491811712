import React from "react"


import CoverageComparison from "./CoverageComparison"
import { useOutlet, useOutletContext } from "react-router-dom"

export default function CoverageComparisonLayout(props) {

    const {plansToCompare} = useOutletContext()[0]

    return (
        plansToCompare.length > 0 ?
            <div className="mt-5 mb-20">
                <CoverageComparison planIds={plansToCompare}/>
            </div>
        :   <h2 className="mt-10">Select plans to compare.</h2>
    )
}
