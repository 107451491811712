import React, { useState, useContext, useRef, useEffect } from 'react'

import { ResultsContext, ApplicationContext } from './Contexts'



import PlanStub from './PlanStub'
import { useOutletContext } from 'react-router-dom'


export default function ResultsSummary(props){
  
  const results = useContext(ResultsContext)
  const {isRecommendedPlans, plansToCompare, setPlansToCompare, isExpandFilters, displayedPlanIds} = useOutletContext()[0]

  useEffect(() => {}, [displayedPlanIds])
  
  const not_found_lkp = {
    'best coverage': 'covers all of your listed providers'
    , 'best no referral required': 'does not require a referral to see a specialist'
  }
  const not_found_txt = results.not_found.map( item => not_found_lkp[item])
    .join(' or ')

  return(
    <div className="step">

      { /* plan summaries */}
      {isRecommendedPlans &&
        <div className="title info">
          {results.not_found.length > 0 &&
            <h3 className='text-center mt-2'>
              We were unable to find a plan that {not_found_txt}.
            </h3>
          }
        </div>
      }

      <div className="details max-w-8xl">
        <PlanList
          isRecommendedPlans={isRecommendedPlans}
          plansToCompare={plansToCompare}
          setPlansToCompare={setPlansToCompare}
          results={results}
          isExpandFilters={isExpandFilters}
          displayedPlanIds={displayedPlanIds}
        />
      </div>
   
    </div>
  )
}

function PlanList(props) {
  const {plansToCompare = [], setPlansToCompare=null, results, displayedPlanIds} = props

  useEffect(() => {}, [displayedPlanIds])

  const samplePlan = results.plans[results.recommended_plan_ids[0]]
  const isProviders = samplePlan.provider_coverage.length > 0
  const isDrugs = samplePlan.drug_coverage.length > 0

  return (

    <div className="details ml-5 mt-8 max-w-6xl">
    { (displayedPlanIds.length > 0) ?
      displayedPlanIds.map(id => 
        <div className='mt-6'>
          <PlanStub 
            key={id}
            plan={results.plans[id]} 
            subsidy={results.subsidy} 
            isDrugs={isDrugs} 
            isProviders={isProviders} 
            isSelectToCompare={true}
            plansToCompare={plansToCompare}
            setPlansToCompare={setPlansToCompare}
          />
        </div>
      ) : 
      <div className="no-match-text mt-10 max-w-6xl">
        <h1 className='text-4xl text-center'> No plans match your criteria.</h1>
      </div>
    }
  </div>
  )
}


