import ChartDataLabels from 'chartjs-plugin-datalabels'
import {externalTooltipHandler} from '../../utils/handlers.js'
import axios from 'axios'
import React, {useState, useEffect} from 'react'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  ChartDataLabels,
  Legend
);


// Returns chart component for picking health status
export default function HealthStatusChart(props){
  
  const {age, sex} = props
  const [apiData, setApiData] = useState()

  // request chartData only once
  useEffect( () => {
    const url = process.env.REACT_APP_API_URL+'/cohortselection' 
    axios.post(url, {age, sex})
      .then(res => setApiData(res.data))
    }
    , [] 
  )

  let chartElem = (<></>)
  let chartTitle = null
  if (apiData) {
    const labels = apiData.usage.map(item => titleCase(item.health_status.replaceAll('_', ' ').toLowerCase()))
    const bars = apiData.usage.map(item => item.share * 100)
    const barChartData = {
      labels
      , datasets: [{
          data: bars,
          minBarLength: 7,
          backgroundColor: ['#1F7401', '#054075', '#EDA00C','#DC9595', '#AC0202'],
        }]
    }
    chartElem = ( <Bar className="mt-5" options={getOptions(apiData)} data={barChartData}></Bar> )

    chartTitle = (
      <h2 className="text-xl text-lumos-blue font-semibold mt-8">
        % of {sex === "M" ? "Male":"Female"}s Aged {apiData.age_bucket[0]} to {apiData.age_bucket[1]} Selecting Each Health Status
      </h2>
    )
  }

  
  return (
    <>
      {chartTitle}
      <div className="cohort-usage-chart">
        <div className="graph mt-10">
          <span id="chartjs-tooltip" style={{width: 1300}}></span>
          {chartElem}
        </div>
      </div>
    </>
  )
}


function getOptions(data) {
  return (
    {   
      layout: { padding: {top: 20}}
      , responsive: true
      , plugins: {
        legend: { display: false }
        , tooltip: {
          enabled: false
          , intersect: false
          , position: 'nearest'
          , padding: 10
          , width: 300
          , callbacks: {
            label: tooltipItem => generateTooltip(tooltipItem, data)
            , title: () => "A typical year for users in this group has:"
          }
          , external: externalTooltipHandler
        }
        , datalabels: {
          display: true
          , color: "black"
          , formatter: (value, ) => Math.round(value) + '%'
          , font: {
            weight: 'normal'
            , size: 14
            , family: "Merriweather"
          }
          , anchor: "end"
          , offset: 0
          , align: "end"
        }
      },
      scales: {
        // to remove the labels
        x: {
          ticks: {
            font: {
              size: 16
              , weight: 'normal'
              , family: "Merriweather"
            }
            , display: true
          }
  
          // to remove the x-axis grid
          , grid: {
            drawBorder: true
            , borderColor: 'black'
            , display: false
          }
        }
        // to remove the y-axis labels
        , y: {
          ticks: {
            display: false
            , beginAtZero: true
          }
          // to remove the y-axis grid
          , grid: {
            drawBorder: false
            , display: false
          }
        }
      }
    }
  )

}

function generateTooltip(tooltipItem, data) { 
  let item = data.usage[tooltipItem.dataIndex]
  return [
    "Doctor Visits: "+getRangeValue(item, 'doctor_visits')
    , "Labs / Imaging: "+getRangeValue(item, 'labs_imaging')
    , "ER Visits: "+getRangeValue(item, 'emergency_room')
    , "Hospitalizations: "+getRangeValue(item, 'hospitalizations')
    , "Outpatient Procedures: "+getRangeValue(item, 'outpatient_procedures')
  ]
}

function getRangeValue(item, field){
  return item[field].length > 1 ? item[field][0] + "-" +item[field][1] : "~"+item[field][0]
}

function titleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}