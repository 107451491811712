import React, { useState } from 'react'
import { useNavigate, useOutletContext, useLocation } from "react-router-dom"

import {getCounties} from '../../utils/apis'
import { getStateSupportType } from '../../utils/utility'
import {getRoute} from './ApplicationLayout'


/**
 * 
 * @param {*} props 
 * @returns {JSX} - Location input UI
 */
export default function Location(props) {

  const {application, setApplication} = useOutletContext()
  const location = useLocation()

  const [errors, setErrors] = useState({location:null})
  const [stateSupport, setStateSupport] = useState('')

  const [zipcode, setZipcode] = useState("")
  const [countyResults, setCountyResults] = useState([])

  const navigate = useNavigate()

  
  function searchZipcode(event){
    // reset location errors
    setErrors( oldErrors => ({...oldErrors, location:null})) 
    // don't allow zip to go past 5 digits
    const newZip = event.target.value.slice(0,5)
    setZipcode(newZip)
    // search for associated counties and set, may come back as empty array
    getCounties(newZip).then( res => {
      if (res.length == 1){
        setApplication( (oldApp) => ({...oldApp, location:res[0]}) )
        console.log(res[0])
        setStateSupport( getStateSupportType(res[0].state) )
      } else {
        setCountyResults(res)
      }
    })
  }

  function hasError() {
    if(application.location) {
      return false
    }

    if ( countyResults && countyResults.length > 0) {
      setErrors({location: ['Please select your county']})
    } else {
      setErrors({location: ['Please provide a valid zipcode']})
    }
    return true
  }

  function nextRoute(navigate){
    if( !hasError() ) {
      navigate(getRoute(application.planYear, location))
    } 
  }

  

  // JSX vars
  // can run into funny issues if defining a React component inside of another

  const topInfo_jsx = 
    <div className="title info text-center">
      <h2 className="application-title-text">Let’s get started, where do you live?</h2>
      <p className="application-subtitle-text">We use this to find which plans are available in your area.</p>
    </div>

  const selectedLocation_jsx = (application.location ?
    <div className="selected mt-2 flex justify-between items-center w-full border border-lumos-blue block py-3 px-3 rounded-lg">
      <span>{application.location.name}, {application.location.state} {application.location.zipcode}</span>
      <span 
        onClick={() => {
          setStateSupport('')
          setApplication( (oldApp) => ({...oldApp, location:null}) )
        }} 
        className="cursor-pointer text-lg"
      >
        &times;
      </span>
    </div>
    : <></>
  )

  const zipcodeInput_jsx = 
    <>
      <label htmlFor="zipcode" className="input-text">Zipcode</label>
      <div className="input flex mt-2">
        <input 
          maxLength="5" 
          onChange={searchZipcode} 
          value={zipcode} 
          type="number" 
          placeholder="Enter your zipcode" 
          className="zipcode w-full border border-lumos-blue block py-3 px-3 rounded-lg"
        />
      </div>
    </>

  const errorElems = ( errors.location?
    errors.location.map((error, index) =>
      <span key={'errors-location-'+index} className="text-red-500 mt-2 block ml-1">{error}</span>
    )
    : <></>
  )

  const countyElems = (countyResults ? 
    countyResults.map( (county, index) => 
      <div 
        onClick={() => {
          setErrors( oldErrors => ({...oldErrors, location:null}) )
          setApplication( (oldApp) => ({...oldApp, location:county}) )
          setStateSupport( getStateSupportType(county.state) )
          setCountyResults([])
          }
        }
        key={"counties-results-"+index} 
        className="result border border-black p-4 rounded cursor-pointer hover:bg-lumos-blue hover:text-white mt-2"
      >
        <span>{county.name}, {county.state} {county.zipcode}</span>
      </div>
    )
    : <></>
  )

  const unsupportedState_jsx = 
    <span className="text-orange-500 text-xl mt-2 block ml-1">
      Your state operates its own state-based healthcare exchange. We are working on being able to serve users in this state but cannot do so yet. You can visit this <a target="_blank" rel="noreferrer" className="text-blue-500" href="https://www.healthcare.gov/marketplace-in-your-state/">link</a> to shop directly on the exchange site for your state.
    </span>

  const partialSupportState_jsx = 
    <div className="banner border border-black mt-6 bg-lumos-light p-5 mx-4">
      <b>Note:</b> We are still working on an enrollment experience for users in your state. However, <b> you can still see and compare plans!</b>
    </div>


  return (
    <div className="step px-10 max-w-2xl mx-auto">
      {topInfo_jsx}

      <div className="inputs zipcode-input">
        { /* display selected location if exists */
          /* else request zipcode input */
          application.location ?
          selectedLocation_jsx
          : zipcodeInput_jsx
        }
        { /* display errors if any */
          errorElems 
        }
      </div>

      {/* display county results for the current input zip */}
      <div className="results px-16 mt-4">
        {countyElems}
      </div>

      {/* render warnings if zip corresponds to a state with limited service */}
      <div className="warnings mt-10 text-center">
        {stateSupport === "unsupported" && unsupportedState_jsx}
        {stateSupport === "partial" && partialSupportState_jsx}
      </div>

      {/* navigation buttons at bottom */}
      <div className="actions navigation-spacing mt-24">
        <button 
          onClick={() => window.location.href = 'https://www.lumos-health.com'}
          className="navigation-button-back">
          Back
        </button>
        {stateSupport !== 'unsupported' && 
          <button onClick={() => nextRoute(navigate)} 
            className="navigation-button-next">
            Next
          </button>
        }
      </div>
    </div>
  )
}
