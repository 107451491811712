import React, { useState, useEffect } from 'react'
import { useNavigate, useOutletContext } from "react-router-dom"
import classNames from 'classnames'
import info from '../../assets/icons/info.svg'


/**
 * 
 * @param {*} props 
 * @returns {JSX} - Location input UI
 */
export default function SpecialEnrollment(props) {

    const {application, setApplication} = useOutletContext()
    const [qualifyingEvent, setQualifyingEvent] = useState(application.qualifyingEvent ? application.qualifyingEvent : "")
    const [errors, setErrors] = useState({qualifyingEvent:null})

    const navigate = useNavigate()

    function hasError() {
        if(application.qualifyingEvent) {
          return false
        } else {
          setErrors({qualifyingEvent: ['Please select an option.']})
        }
        return true
      }

    function nextRoute() {
        if (hasError()){
            return
        }
        navigate('../providers')
      }
    
    function prevRoute() {
    navigate('../subsidy')
    }

    console.log(qualifyingEvent)

    useEffect(
        () => setApplication( old => ({...old, qualifyingEvent}) )
        , [qualifyingEvent]
      )

    const options = [
        {
            text: "Lost my health insurance coverage"
            , slug: "lost-coverage"
            , tooltipText: "Could be due to losing job-based coverage, divorce, COBRA running out, aging off of a parent's plan at 26, or losing eligibility for Medicaid or CHIP."
        }
        , {
            text: "Moved recently"
            , slug: "move"
            , tooltipText: "Must have had qualifying coverage for at least 1 day in the 60 days before moving."
        }
        
        , {
            text: "Change in household size"
            , slug: "household-size"
            , tooltipText: "Got married or gained a dependent."
        }
        , {
            text: "Offered an HRA or QSHERA from my employer"
            , slug: "HRA"
            , tooltipText: "These are arrangements where your employer reimburses you health expenses, including insurance premiums."
        }
        , {
            text: "Other"
            , slug: "other"
            // , tooltipText: "we'll discuss this in detail at time of enrollment"
        }
        , {
            text: "None of the above"
            , slug: "none"
            // , tooltipText: "you can still view plans but will be unable to enroll"
        }
    ]

  
  return (
    <div className="step special-enrollment-component">
        {/* top info */}
        <div className="title info mt-10 text-center">
            <h2 className="application-title-text">It’s currently Special Enrollment season.</h2>
            <p className="application-subtitle-text mt-6 household-subtitle-spacing">This means you need a qualifying event within the past two months to enroll.</p>
        </div>

        <div className="inputs special-enrollment-component my-10">
            <p className="input-text">Which of the following applies to you?</p>
            {options.map( opt => (
                <Option 
                    qualifyingEvent={qualifyingEvent}
                    setQualifyingEvent={setQualifyingEvent}
                    text={opt.text}
                    slug={opt.slug}
                    tooltipText={opt.tooltipText}
                />
            ))}
            <span className="text-red-500 mt-2 block ml-1">{errors.qualifyingEvent}</span>
        </div>

        { qualifyingEvent === "none" &&
            <div className="banner text-left border border-black mt-10 bg-lumos-light p-5 mx-4">
                <p>
                    If none of these situations apply to you, then it’s most likely you’ll be unable to qualify until the next Open Enrollment, which starts Nov 1, 2023.
                </p>
                <br/>
                <p>
                    You may still shop and compare plans without enrolling.
                </p>
            </div>
        }

        { qualifyingEvent === "other" &&
            <div className="banner text-left border border-black mt-10 bg-lumos-light p-5 mx-4">
                <p>
                    Make sure you qualify by using this <a className="text-indigo-700 underline"href='https://www.healthcare.gov/screener/'>this tool from Healthcare.gov</a>.  It will help you check for all possible qualifying events.
                </p>
            </div>
        }

        {/* navigation */}
        <div className="actions navigation-spacing mt-20">
        <button 
            onClick={prevRoute} 
            className="navigation-button-back"
        >
            Back
        </button>
        <button onClick={() => nextRoute(navigate)} className="navigation-button-next">
        Next
        </button>
        </div>

    </div>
  )
}

function Option(props){

    const {qualifyingEvent, setQualifyingEvent, text, slug, tooltipText} = props

    console.log(qualifyingEvent)

    return (
        <label
            htmlFor={slug}
            className={classNames(
                'input cursor-pointer flex items-center p-3 mt-2 rounded-lg border border-lumos-blue hover:bg-lumos-light',
                { 'bg-lumos-light': (qualifyingEvent === slug) }
            )}
        >
            <input
                id={slug}
                type="checkbox"
                checked={qualifyingEvent === slug}
                name={slug}
                onChange={(e) => {
                    if (e.target.checked){
                        setQualifyingEvent(slug)
                    } else {
                        setQualifyingEvent(null)
                    }
                }}
            />
            <label htmlFor={slug} className="ml-2 cursor-pointer w-full">
                {text}
            </label>

            {/* tooltip */}
            {tooltipText &&
                <div className="tooltip-container ml-auto">
                    <div className="tooltip">
                    <span className="ml-auto">
                        <img src={info} alt="Info" />
                    </span>
                    <i className="icon ri-arrow-right-line" />
                    <span className="tooltip-drop tooltip-left">{tooltipText}</span>
                    </div>
                </div>
            }
        </label>
    )
}
