import React, {useContext} from "react"
import {useNavigate} from "react-router-dom"

import greenCheck from '../../assets/icons/green-check.svg'
import redCross from '../../assets/icons/red-cross.svg'

import { ResultsContext } from './Contexts'

import { getLogo } from "../../utils/utility"
import classNames from 'classnames'
  
export default function CoverageComparison(props) {

    const {planIds} = props
    const results = useContext(ResultsContext)
    const plans = planIds.map(id => results.plans[id])

    const isDrugs = plans[0].drug_coverage.length > 0
    const isProviders = plans[0].provider_coverage.length > 0

    const navigate = useNavigate()

    // get into a better format for iterating
    const providerCoverage = plans[0].provider_coverage
        .map(provider => plans.map(plan => plan.provider_coverage
                .find(p => (p.npi === provider.npi))
                .is_covered
            )
        )
    const providerNames = plans[0].provider_coverage.map(provider => provider.name)

    // console.log(providerCoverage)

    const drugCoverage = plans[0].drug_coverage
        .map(drug => plans.map(plan => plan.drug_coverage
                .find(d => (d.rxcui === drug.rxcui))
                .is_covered
            )
        )
    const drugNames = plans[0].drug_coverage.map(drug => drug.name)

    return (
        <>
            <div 
                style={{
                    display:'grid'
                    , gridTemplateColumns: `200px ${'240px '.repeat(plans.length)}`
                }}
            >
                {plans.map(plan => 
                    <button className="w-full border border-black border-b-0 border-l-0 first:border-l first:col-start-2 p-2 flex flex-col max-h-400 items-center"
                        onClick={() => navigate(`/results/view/${plan.id}`)} 
                    >
                        <img 
                            style={{minWidth:'80px', maxWidth:'140px', maxHeight:'30px'}}
                            src={getLogo(plan.issuer)} 
                            alt={plan.issuer}
                        /> 
                        <p className="line-clamp-3 w-full mt-2 text-xs">
                            {plan.plan_name}
                        </p>
                    </button>
                )}
                {isProviders && <CoverageRows coverage={providerCoverage} names={providerNames} title='Providers'/>}
                {isDrugs && <CoverageRows coverage={drugCoverage} names={drugNames} title='Drugs'/>}
                
            </div>
        
        </>
    )
}

function CoverageRows(props){
    const {coverage, names, title} = props
    return (
        <>
            <div className="col-span-full">
                <h2 className="text-white bg-lumos-blue py-2 pl-2 font-bold">{title}</h2>
            </div>
            {coverage.map( (item, index) => 
                <>
                    <span className={classNames('border-r border-b border-l border-black pl-2 py-3 text-sm', {'bg-slate-100': index % 2 === 0})}>{names[index]}</span>
                    {item.map(isPlanCovers =>
                        <div className={classNames('border-r border-b border-black w-full h-full flex items-center justify-center ', {'bg-slate-100': index % 2 === 0})}>
                            <img src={isPlanCovers ? greenCheck : redCross} alt="checkmark" 
                                className='h-4'
                            />
                        </div>
                    )}
                </>
            )}
        </>
    )
}
