import React, {useContext} from "react"

import { Chart as ChartJS, CategoryScale, PointElement, LinearScale, LineElement, Tooltip, Legend } from 'chart.js'
import { Line, Bar } from 'react-chartjs-2'

import { ResultsContext, ApplicationContext } from './Contexts'
import { useNavigate } from 'react-router-dom'

import { commaFormat, getLogo } from "../../utils/utility"
import {costOutcomeTooltipHandler} from '../../utils/tooltipHandlers'
import YoutubePlayer from "../YoutubePlayer"
  
ChartJS.register( CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend )

const COLORS = [
    "#AC0202",
    "#1F7401",
    "#054075",
    "#F8A403",
    "#000000",
    "#DC9595"
    ]

export default function CostComparison(props) {

    const {planIds} = props
    const results = useContext(ResultsContext)
    const plans = planIds.map( id => results.plans[id])

    const scenarioInfo = [
        {label: 'Premiums Only', desc: "If you only end up needing preventive care."}
        , {label: 'Fairway Case', desc: 'A typical year. About half of similar families experience a higher cost.'}
        , {label: 'Poor Outcome' , desc: 'An unexpected year. Only ~10% of similar families experience a higher cost.'}
        , {label: 'Max Cost', desc: "Claims are covered 100% once you reach the out-of-pocket maximum."}
        , {label: 'True Cost', desc: "The average cost across similar families."}
    ]

    return (
        <>

            {/* chart title */}
            <h2 className="text-lumos-blue text-2xl font-semibold text-center mt-8">How much might I spend <u>for the year</u> under {plans.length > 1 ? 'each' : 'this'} plan?</h2>
            

            {/* legend */}
                        {plans.length > 1 &&
                <div className="grid grid-cols-3 mt-7 border rounded-lg border-slate-400 p-4">
                    {plans.map( (plan,index) => 
                    <>
                    <div className="flex items-start mt-1">
                        <div>
                            <div 
                                style={{backgroundColor: COLORS[index], width:"15px", height:"15px"}}
                                className="mx-2 mt-1"
                            />
                        </div>
                        <div>
                            <p className="text-sm line-clamp-2"> 
                                {/* <img className="w-20 max-h-10 inline mr-1" src={getLogo(plan.issuer)} alt={plan.issuer}/>  */}
                                {plan.issuer}: {plan.plan_name}
                            </p>
                        </div>
                    </div>
                    </>)}
                </div>
            }


            {/* begin chart */}
            <div className="flex-vertical relative mt-8">

                {/* overlay scenario descriptions */}
                <div className="absolute pb-10 h-full -z-10">
                    <div className="grid grid-cols-5 mt-2 pl-14 h-full">
                        {scenarioInfo.map( item => 
                        <div className="border-r-2 border-slate-300 border-dashed w-full px-4">
                            <h2 className="text-base text-center">{item.desc}</h2>
                        </div>
                        )}
                    </div>
                </div>
                <div className="flex chart-container">
                    {/* <span id="chartjs-tooltip"></span> */}
                    <div className="consequence-graph">
                        <ConsequenceGraph plans={plans}/>
                    </div>
                    <div className="true-cost-graph">
                        <TrueCostChart plans={plans} />
                    </div>
                </div>
                {/* explainer video */}
                <YoutubePlayer/>
            </div>
        </>
    )
}

function ConsequenceGraph(props) {
    const {plans} = props
    const {subsidy} = useContext(ResultsContext)

    const labels = ['Premiums Only', 'Fairway Case', 'Poor Outcome', 'Max Cost']

    const percentileLkp = {
        'Premiums Only': 0
        , 'Fairway Case': 50
        , 'Poor Outcome': 95
        , 'Max Cost': 100
    }

    const costArray = plans.map( plan => ({
        netPremium: commaFormat( Math.max((plan.premium - subsidy)*12,0) )
        , oop: labels.map( label => {
            const percentile = percentileLkp[label]
            const entry = plan.consequence_graph.find( v => v.percentile === percentile)
            return commaFormat( entry.true_cost - Math.max((plan.premium - subsidy)*12,0) )
        })
    }))

    const options = {
        responsive: true,
        plugins: {
            legend: {
                labels: { usePointStyle: true, pointStyle: 'line' }
                , position: 'chartArea'
                , align: 'start'
                , display: false
            }
            , datalabels: {display: false }
            , tooltip: {
                enabled: false,
                position: 'nearest',
                external: context => costOutcomeTooltipHandler(context, costArray)
            }
        }
        , interaction : {
            intersect: false
        }
        
        , maintainAspectRatio: false
        , scales: {
          // to remove the labels
            x: {
                ticks: {   
                    font: { size: 16, weight: 'normal', family: "Avenir" }
                    , display: true
                }
                , offset: true
                , display: true
        
                // to remove the x-axis grid
                , grid: { borderColor: 'black', drawBorder: true, display: false }
            }

            // to remove the y-axis labels
            , y: {
                ticks: {
                    callback: function(value, index, values) { return '$' + commaFormat(value.toFixed(0)) }
                    , font: { size: 12, weight: 'normal', family: "Avenir" }
                    , display: true
                    , includeBounds: false
                },
                // to remove the y-axis grid
                grid: { borderColor: 'black', drawBorder: true, display: false, lines: { drawTicks: true } }
                , min: 0
                , max: 1.3 * Math.max( 
                    ...plans.map( p => 
                        Math.max( ...p.consequence_graph.map( v => v.true_cost) )
                    )
                )
                
            }
        }
    }


    const datasets = plans.map( (plan, i) => {
        console.log(plan)
        const total_cost = labels.map( label => {
            const percentile = percentileLkp[label]
            const entry = plan.consequence_graph.find( v => v.percentile === percentile)
            return entry.true_cost
        })
        return {
            label: plan.issuer + " - " + plan.plan_name,
            data: total_cost,
            borderColor: COLORS[i],
            backgroundColor: [COLORS[i]],
        }
    })
    
    const data = {
        labels,
        datasets
    }

    return (
        <Line options={options} data={data}></Line>
    )
}

function TrueCostChart(props) {
    const {plans} = props
    const {subsidy} = useContext(ResultsContext)
    
    const costArray = plans.map( plan => ({
        netPremium: commaFormat( Math.max((plan.premium - subsidy)*12,0 ))
        , oop: [commaFormat( plan.oop_mean )]
        })
    )

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display:false
            }
            , datalabels: {display: true, align: 'top', anchor: 'end', formatter: (value, context) => '$'+commaFormat(value) }
            , tooltip: {
                enabled: false,
                position: 'nearest',
                external: context => costOutcomeTooltipHandler(context, costArray)
            }
        }
        , maintainAspectRatio: false
        , scales: {
          // to remove the labels
            x: {
                ticks: {   
                    font: { size: 16, weight: 'normal', family: "Avenir" }
                    , display: true
                }
                , display: true // display x-lables another way
        
                // to remove the x-axis grid
                , grid: { borderColor: 'black', drawBorder: true, display: false }
            }

            // to remove the y-axis labels
            , y: {
                ticks: {
                callback: function(value, index, values) { return '$' + commaFormat(value.toFixed(0)) }
                , font: { size: 12, weight: 'normal', family: "Avenir" }
                , display: false
                , includeBounds: false
                },
                // to remove the y-axis grid
                grid: { drawBorder: true, display: false, lines: { drawTicks: false } }
                , min:0
                , max: 1.3 * Math.max( 
                    ...plans.map( p => 
                        Math.max( ...p.consequence_graph.map( v => v.true_cost) )
                    )
                )
                , display:false
            }
        }
    }

    
    // build data
    const labels = ['True Cost']

    const datasets = plans.map( (plan, i) => ({
            label: plan.issuer + " - " + plan.plan_name
            , data: [plan.true_cost]
            , borderColor: COLORS[i]
            , backgroundColor: [COLORS[i]]
        })
    )
    
    const data = {
        labels,
        datasets
    }
    
    // options.scales.y['max'] = Math.max( ...datasets.map( d => Math.max(...d.data)))*1.1

    return (
        <Bar options={options} data={data}></Bar>
    )
}
