import { commaFormat } from "./utility";

function getOrCreateTooltip(chart) {
    // creates a div element just outside of chart to use as the tooltip.
    let tooltipEl = chart.canvas.parentNode.querySelector('div');
  
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'white';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.borderColor = 'black'
      tooltipEl.style.borderWidth = '1px'

    //   tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 0;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';
      tooltipEl.style.maxWidth = "200px"
  
    //   const table = document.createElement('table');
    //   table.style.margin = '0px';
  
    //   tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
  
    return tooltipEl;
  };

  function buildTooltipContent(tooltip, costArray){

    const {datasetIndex, dataIndex, raw, dataset} = tooltip.dataPoints[0]
    const costObj = costArray[datasetIndex]

    const content = document.createElement('div');
    content.style.fontSize = '0.8rem'

    const title = document.createElement('div')
    title.style.overflowWrap = "normal"
    title.className += ' line-clamp-3'

    // color indicator
    const span = document.createElement('span');
    span.style.background = dataset.backgroundColor;
    span.style.borderColor = dataset.borderColor;
    span.style.borderWidth = '2px';
    span.style.marginRight = '10px';
    span.style.height = '10px';
    span.style.width = '10px';
    span.style.display = 'inline-block';

    const text = document.createTextNode(dataset.label);

    title.appendChild(span)
    title.appendChild(text)

    // build the grid
    const grid = document.createElement('div');
    grid.style.display = 'grid'
    grid.style.gridTemplateColumns = '[label] 2fr [ops] 10px [value] 1fr [end]'
    grid.style.marginTop = "5px"
    // fill the grid
    
    const rows = [
        { label: 'Premiums', value: `${costObj.netPremium}`}
        , {label: (tooltip.dataPoints[0].label === 'Max Cost' ? 'OOP Max': 'Out of Pocket'), value: `${costObj.oop[dataIndex]}`}
        , {label: 'Total Cost', value: `$${commaFormat(raw)}`}
    ]

    rows.forEach( (item,i) => {
        const labelEl = document.createElement('div')
        labelEl.textContent = item.label
        labelEl.style.paddingRight = "10px"
        grid.appendChild(labelEl)

        if (i==1){
            const opsEl = document.createElement('div')
            opsEl.textContent='+'
            grid.appendChild(opsEl)
        }
        
        const valueEl = document.createElement('div')
        valueEl.textContent = item.value
        valueEl.style.gridColumnStart = 'value'
        valueEl.style.textAlign = 'right'
        grid.appendChild(valueEl)

        if (i==1) {
            const hrEl = document.createElement('hr')
            hrEl.style.gridColumn = 'ops / end'
            hrEl.style.borderColor = 'black'
            grid.appendChild(hrEl)
        }
    })

    content.appendChild(title)
    content.appendChild(grid)

    return content
  }
  
  export function costOutcomeTooltipHandler(context, costArray){
    // Tooltip Element
    const {chart, tooltip} = context;
    const tooltipEl = getOrCreateTooltip(chart);

  
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
  
    const contentEl = buildTooltipContent(tooltip, costArray)
    // Set Text
    if (tooltip.body) { // do you really need this test? Isn't this always true?
  
      // Remove old children
      while (tooltipEl.firstChild) {
        tooltipEl.firstChild.remove();
      }
  
      // Add new children
      tooltipEl.appendChild(contentEl);
    }
  
    const {offsetLeft: positionX, offsetTop: positionY, offsetWidth: width, offsetHeight: height} = chart.canvas;
  
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX - tooltipEl.clientWidth/2 - 6 + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY - tooltipEl.clientHeight - 6 + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';

    console.log(tooltipEl)
  };