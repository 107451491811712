import logo from './assets/images/logo.svg';

import ApplicationLayout from './components/application/ApplicationLayout';
import Location from './components/application/Location';
import Applicants from './components/application/Applicants'
import Household from './components/application/Household'
import Subsidy from './components/application/Subsidy'
import Providers from './components/application/Providers'
import Drugs from './components/application/Drugs'
import UsageExplainer from './components/application/UsageExplainer'
import HealthStatus from './components/application/HealthStatus'
import HealthStatusHelp from './components/application/HealthStatusHelp'
import Preferences from './components/application/Preferences'
import Submit from './components/application/Submit'

import ResultsLayout from './components/results/ResultsLayout'
import LoadingPage from './components/results/LoadingPage'
import LoadingComplete from './components/results/LoadingComplete'
import LoadingReturnUser from './components/results/LoadingReturnUser'

import ShopLayout from './components/results/ShopLayout'
import ResultsSummary from './components/results/ResultsSummary'
import CostComparisonLayout from './components/results/CostComparisonLayout'
import CoverageComparisonLayout from './components/results/CoverageComparisonLayout'
import AllPlans from './components/results/AllPlans'

import ViewPlan from './components/results/ViewPlan';
import Enroll from './components/results/Enroll';

import React from 'react';
import { Route, Routes, Navigate } from "react-router-dom"
import usePageTracking from './hooks/usePageTracking';
import SpecialEnrollment from './components/application/SpecialEnrollment';
import { H } from 'highlight.run';

/**
 * React component defining the header and routes.
 * @returns {JSX} - Root component
 */
export default function App() {

  usePageTracking();
  H.init('3ejl8mvd', {
    serviceName: "lumos-marketplace-app",
    tracingOrigins: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        "https://www.googleapis.com/identitytoolkit",
        "https://securetoken.googleapis.com",
      ],
    },
  });

  return (
    <div className="app">
      <header className="bg-lumos-blue flex justify-between items-center">
        <a href="https://www.lumos-health.com">
          <img src={logo} className="logo p-4" alt="logo" />
        </a>
        {/* <div 
          className="sharethis-inline-share-buttons" 
          target="_blank" 
          data-url="www.lumos-health.com"
          data-title="The best way to pick a health insurance plan!"
          data-email-subject="The best way to pick a health insurance plan!"
          data-description = "Lumos Health lets you compare plans based on true cost, not just premiums.  Picking the right plan could save you hundreds of dollars each year!"
          data-message = "Lumos Health lets you compare plans based on true cost, not just premiums.  Picking the right plan could save you hundreds of dollars each year! www.lumos-health.com"
        >
        </div> */}
        <div className='flex row'>
            {/* <a href="https://forms.gle/yYqe9cPkLmG18xyW6" className="nav-link w-nav-link text-white px-4 py-2 border mr-4 rounded-lg" target="_blank">Feedback</a> */}
            <a href="https://us13.list-manage.com/contact-form?u=d29ee4227aa7ba1040e666c79&form_id=f8d711c6ebb9ecec9ec83baada70f03b" className="nav-link w-nav-link contact-us" target="_blank">Contact Us</a>
        </div>
        
      </header>
      <main>
          <Routes>
            <Route exact path= "/" element={<Navigate replace to="/application/location"/>}></Route>
            <Route path= "/application" element={<ApplicationLayout/>}>
              <Route path= "location" element={<Location />} />
              <Route path= "applicants" element={<Applicants />} />
              <Route path= "household" element={<Household />} />
              <Route path= "subsidy" element={<Subsidy />} />
              <Route path="specialEnrollment" element={<SpecialEnrollment />} />
              <Route path= "providers" element={<Providers />} />
              <Route path= "drugs" element={<Drugs />} />
              {/* <Route path= "usageExplainer" element={<UsageExplainer />} /> */}
              <Route path= "healthStatus" element={<HealthStatus />} />
              {/* <Route path= "healthStatusHelp" element={<HealthStatusHelp/>} /> */}
              <Route path= "preferences" element= {<Preferences />} />
              <Route path= "submit" element={<Submit />} />
            </Route>
            <Route path= "/results" element={<ResultsLayout />}>
              <Route path="loading" element={<LoadingPage />} />
              <Route path="loadingcomplete" element={<LoadingComplete />} />
              <Route path="loadingreturnuser" element={<LoadingReturnUser />} />
              <Route path="shop" element={<ShopLayout />} >
                <Route path="summary" element={<ResultsSummary/>} />
                <Route path="cost" element={<CostComparisonLayout/>} />
                <Route path="coverage" element={<CoverageComparisonLayout/>} />
                <Route path="allplans" element={<AllPlans/>} />
              </Route>
              <Route path="view/:id" element={<ViewPlan />} />
              <Route path="enroll/:id" element={<Enroll />} />
            </Route>
          </Routes>

          {/* <div className="text-center px-3 mt-36 md:hidden">
            <h2 className="text-3xl text-lumos-blue font-semibold">This experience is designed for your desktop. <br/><br/> Mobile experience coming soon.</h2>
          </div> */}
      </main>
    </div>
  );
}
