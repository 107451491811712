import React, {useRef, createRef, useCallback, useState, useContext} from 'react'
import { isEmail } from '../../utils/utility'
import { getCurrentDate } from '../../utils/utility'
import { PatternFormat } from 'react-number-format';
import { enrollRequest } from '../../utils/apis';
import { useNavigate, useParams } from 'react-router-dom';
import { ResultsContext, ApplicationContext } from './Contexts';
import PlanStub from './PlanStub'
import classNames from 'classnames';
import SignatureCanvas from 'react-signature-canvas'
// import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import info from '../../assets/icons/info.svg'
import { set } from 'lodash';


export default function Enroll() {

    const results = useContext(ResultsContext)
    const navigate = useNavigate()

    console.log(results.app_id)

    const {id} = useParams()

    const plan = results.plans[id]
    const isProviders = plan.provider_coverage.length > 0
    const isDrugs = plan.drug_coverage.length > 0
    
    return (
        <div className="step">
          <div className="bar w-full">
            <button onClick={() => navigate('/results/shop/summary')} className="text-lg text-lumos-blue">&#x2190; Back</button>
          </div>
          <div className="title info mt-10">
            <h2 className="text-3xl text-lumos-blue text-center font-semibold">
                Congratulations on selecting a plan!
            </h2>
            
            <div className='flex flex-col mt-10'>
                <PlanStub 
                plan={plan} 
                subsidy={results.subsidy} 
                isDrugs={isDrugs} 
                isProviders={isProviders} 
                isShowEnrollButton={false}
                isExpanded={true}
                onEnroll={true}
                />
            </div>

            <Contact plan_id={id} navigate={navigate}/>
          </div>
        </div>
    )
}

function Contact(props) {

    // const options = {
    //     // default is `save`
    //     method: 'build',
    //     // default is Resolution.MEDIUM = 3, which should be enough, higher values
    //     // increases the image quality but also the size of the PDF, so be careful
    //     // using values higher than 10 when having multiple pages generated, it
    //     // might cause the page to crash or hang.
    //     resolution: Resolution.MEDIUM,
    //     page: {
    //        // margin is in MM, default is Margin.NONE = 0
    //        margin: Margin.SMALL,
    //        // default is 'A4'
    //        // default is 'portrait'
    //        orientation: 'landscape',
    //     },
    //     canvas: {
    //        // default is 'image/jpeg' for better size performance
    //        mimeType: 'image/jpeg',
    //        qualityRatio: 0.5
    //     },
    //  };

    const {plan_id, navigate} = props

    const application = useContext(ApplicationContext)
    const results = useContext(ResultsContext)
    const issuer = results.plans[plan_id].issuer

    const benefits_url = results.plans[plan_id].links.filter(link => link.name === "Summary of Benefits")[0].url

    const padRef = useRef(null);
    const [canvas, setCanvas] = useState(null);
    const [canvasVisibility, setCanvasVisibility] = useState(false);

    const [form, setForm] = useState({
        full_name: ""
        , dob: ""
        , email: application.email || ""
        , phone: ""
        , additional_comments: ""
        , is_consent: false
        , signature: ""
    })

    const [errors, setErrors] = useState({})
    const [isSent, setIsSent] = useState(false)
    const [enrollLink, setEnrollLink] = useState('')
    const [loading, setLoading] = useState(false)

    const fullNameRef = useRef(null);
    const dobRef = useRef(null);
    const emailRef = useRef(null);

    function handleFormChange(field, value){
        setForm( oldForm => {
            const newForm = {...oldForm}
            newForm[field] = value
            return newForm
        })
        
    }

    function handleSignSave() {
        handleFormChange('is_consent', true);
        handleFormChange('signature', padRef.current.toDataURL());
        padRef.current.off();
        // const getTargetElement = () => document.getElementById('privacy-statement');
    }

    const clearSignatureCanvas = useCallback(() => {
        padRef?.current?.clear();
        setCanvas(undefined);
        setCanvasVisibility(false);
        handleFormChange('is_consent', false);
        handleFormChange('signature', "");
        padRef.current.on();
      }, []);

    function errorCheck(){
        const updatedErrors = {}

        let isError = false

        if (!isEmail(form.email)) {
            updatedErrors.email = ["Please provide a valid email"]
            isError = true
            emailRef.current.scrollIntoView({ behavior: "smooth", block:"start"});
        }
      
        if(form.full_name === undefined || form.full_name === null || form.full_name === ""){
            updatedErrors.full_name = ["Please provide your name"]
            isError = true
            fullNameRef.current.scrollIntoView({ behavior: "smooth", block:"start"});
        }
      
        if(form.email === null || form.email === "" || !form.email){
            updatedErrors.email = ["Please provide an email"]
            isError = true
            emailRef.current.scrollIntoView({ behavior: "smooth", block:"start"});
        }

        if(form.dob === "" ){
            updatedErrors.dob = ["Please provide your date of birth"]
            isError = true
            dobRef.current.scrollIntoView({ behavior: "smooth", block:"start"});
        }
        
        if(form.is_consent === false){
            updatedErrors.is_consent = ["Please add or save your signature"]
            isError = true
        }

        setErrors(updatedErrors)
        return isError
    }

    function submitContactForm(){
    
        if(errorCheck()){
          return
        }
          
        setLoading(true)
        enrollRequest(results.app_id, form, plan_id, issuer)
            .then(response => {
                if(response.status === 200) {
                    setIsSent(true)
                    setLoading(false)
                    setEnrollLink(response.data.enrollLink)
                }
            })
    }

    return (
        <div className="contact-details mt-10" id="full-contact-form">
            {/* <h2 className="text-lumos-blue text-xl mt-10">Contact Information</h2> */}
            <hr className="border border-lumos-blue w-full mt-2"/>
            
            {/* show form if not yet sent */}
            {!isSent && 
                <div className='mt-10 highlight-mask'>
                    <h2 className="text-3xl text-lumos-blue text-center font-semibold">
                        Provide your consent below to start the enrollment process.
                    </h2>

                    <div className="col-span-2" id="privacy-statement">
                            {/* <label className="text-lumos-blue font-semibold text-lg">
                                Privacy Statement 
                            </label>  */}
                            <div className={classNames("py-5 px-6 mt-2 rounded-lg border border-lumos-blue text-sm")}>
                                <label className="text-base text-lumos-blue font-medium">
                                    Privacy and Consent Form<br></br>
                                </label>
                                <div className="rounded-lg border border-slate-300 mt-3 p-3">
                                    Per Federal guidelines, we need to obtain your consent in order to assist you in selecting and enrolling in Marketplace coverage. Please review information below prior to consenting via date and signature to understand how your information will be used and how you can opt out of this consent in the future. <br></br><br></br>I give my permission to <b>Lumos Health Insurance Agency LLC</b> to to serve as the health insurance agent or broker for myself and my entire household if applicable, for purposes of enrollment in a Qualified Health Plan offered on the Federally Facilitated Marketplace. By consenting to this agreement, I authorize the above-mentioned Agent to view and use the confidential information provided by me in writing, electronically, or by telephone only for the purposes of one or more of the following:
                                    <ul className="list-disc pl-7 fa-ul2 text-sm">
                                        <li className="mt-2">
                                            Searching for an existing Marketplace application
                                        </li>
                                        <li className=''>
                                            Completing an application for eligibility and enrollment in a Marketplace Qualified Health Plan or other government insurance affordability programs, such as Medicaid and CHIP or advance tax credits to help pay for Marketplace premiums
                                        </li>
                                        <li className=''>
                                            Providing ongoing account maintenance and enrollment assistance, as necessary; or
                                        </li>
                                        <li className=''>
                                            Responding to inquiries from the Marketplace regarding my Marketplace application
                                        </li>
                                    </ul>
                                    <br></br>I understand that the Agent will not use or share my personally identifiable information (PII) for any purposes other than those listed above. The Agent will ensure that my PII is kept private and safe when collecting, storing, and using my PII for the stated purposes above. 
                                    <br></br><br></br>I understand that I do not have to share additional personal information about myself or my health with my Agent beyond what is required on the application for eligibility and enrollment purposes. I understand that my consent remains in effect until I revoke it, and I may revoke or modify my consent at any time by emailing contact@lumos-health.com

                                    <br></br><br></br>
                                    Name of Primary Writing Agent 1: Esteban Pimentel<br></br>
                                    Agent  National Producer Number: 20472494
                                    <br></br><br></br>
                                    Name of Primary Writing Agent 2: Moonish Maredia<br></br>
                                    Agent  National Producer Number: 20496983
                                    <br></br><br></br>
                                    Name of Agency: Lumos Health Insurance Agency LLC
                                    Agency National Producer Number: 20816215
                                    Email Address: contact@lumos-health.com


                                </div>
                                <div className="mt-4">
                                    <div className="text-base text-lumos-blue font-medium mb-1">
                                        Date
                                    </div>
                                    <div className="p-2 bg-slate-100 w-24 rounded-lg text-sm text-black font-normal border border-slate-300">
                                            {getCurrentDate()}
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="text-base text-lumos-blue font-medium mb-1">
                                        Signature
                                    </div>
                                </div>
                                <div className={form.is_consent ? 'rounded-lg border border-slate-300 border-lumos-blue mb-8 h-24 bg-slate-100' : 'rounded-lg border border-slate-300 border-lumos-blue mb-8 h-24'}>
                                    <SignatureCanvas ref={padRef} penColor='blue' canvasProps={{width: 1120, height: 92, className:'sigCanvas'}} />
                                    <button 
                                        className="text-base font-normal ml-1 mt-2 px-4 py-1 rounded text-white bg-lumos-blue" 
                                        onClick={clearSignatureCanvas}
                                    >
                                        clear
                                    </button>
                                    <button 
                                        className="text-base font-normal ml-4 mt-2 px-4 py-1 rounded text-white bg-lumos-blue" 
                                        onClick={handleSignSave}
                                    >
                                        save
                                    </button>
                                </div>
                                <span key={'errors-consent'} className="text-red-500 block pt-2 ml-1">{errors.is_consent}</span>
                            </div>
                        </div>

                    <div className="form grid grid-cols-2 mt-10 gap-x-10 gap-y-4">

                        <div className="field">
                            <label className="text-lumos-blue font-semibold">Full Name</label>
                            <input
                                ref={fullNameRef} 
                                onChange={(e) => handleFormChange('full_name', e.target.value)} 
                                type="text" placeholder="Full Name" 
                                className="mt-2 mt-2 w-full border border-lumos-blue block py-3 px-3 rounded-lg"
                            />
                            <span key={'errors-full_name'} className="text-red-500 mt-2 block ml-1">{errors.full_name}</span>
                        </div>

                        <div className="field">
                            <label className="text-lumos-blue font-semibold">Date of Birth</label>
                            <div className="tooltip-container ml-2  mb-1 align-middle">
                                <div className="tooltip">
                                    <span className="ml-auto">
                                        <img src={info} alt="Info"/>
                                    </span>
                                    <i className="icon ri-arrow-right-line" />
                                    <span className="tooltip-drop tooltip-right">
                                        We need this information to search for and verify the status of your application.
                                    </span>
                                </div>
                            </div>
                            <input
                                ref={dobRef} 
                                type="date" 
                                onChange={(e) => {
                                    handleFormChange('dob', e.target.value)
                                }} 
                                value={form.dob} 
                                className="mt-2 w-full border border-lumos-blue block py-3 px-3 rounded-lg  highlight-mask"
                            />
                            <span key={'errors-dob'} className="text-red-500 mt-2 block ml-1">{errors.dob}</span>
                        </div>

                        <div className="field">
                            <label className="text-lumos-blue font-semibold">Email Address</label>
                            <input
                                ref={emailRef} 
                                type="text" 
                                onChange={(e) => handleFormChange('email', e.target.value)} 
                                value={form.email} 
                                placeholder="Email" 
                                className="mt-2 w-full border border-lumos-blue block py-3 px-3 rounded-lg  highlight-mask"
                            />
                            <span key={'errors-email'} className="text-red-500 mt-2 block ml-1">{errors.email}</span>
                        </div>

                        {/* <div className="field">
                            <label className="text-lumos-blue font-semibold">
                                Phone # 
                                <span className="text-xs ml-1">(Optional)</span>
                                
                            </label>
                            <PatternFormat 
                                placeholder="Phone Number" 
                                className="mt-2 w-full border border-lumos-blue block py-3 px-3 rounded-lg highlight-mask"
                                valueIsNumericString 
                                format="###-###-####" 
                                mask="_" 
                                onChange={(e) => handleFormChange('phone', e.target.value)}
                            />
                            <span key={'errors-phone'} className="text-red-500 mt-2 block ml-1">{errors.phone}</span>
                        </div> */}

                        <div className="field">
                            <label className="text-lumos-blue font-semibold">
                                Additional Comments  
                                <span className="text-xs ml-1">(Optional)</span>
                            </label>
                            <textarea 
                                type="text" 
                                onChange={(e) => handleFormChange('additional_comments', e.target.value)} 
                                placeholder="Additional Comments" 
                                className="mt-2 w-full border border-lumos-blue block py-3 px-3 rounded-lg"
                            />
                        </div>
                        

                        <div className="button text-center mt-6 col-span-2">
                            <button 
                                onClick={() => submitContactForm()} 
                                className="px-24 rounded text-white text-2xl py-4 bg-lumos-green"
                                disabled={loading}
                            >
                                <span>Provide Consent</span>
                                {loading && 
                                    <span>
                                        <img className="inline w-8 ml-3" src="/loading.gif" alt=""/>
                                    </span>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            }

            {/* if form has already beens sent */}
            {isSent && 
                <div className="thanks my-10 text-center">
                    <div>
                        <h1 className="text-lumos-green text-3xl px-20 py-0">Consent received. You are now ready to enroll!</h1>
                        <div className="text-left mt-10">
                            <p>Make sure that you have:</p>
                            <ul className='list-disc ml-8'>
                                <li>confirmed directly with your doctors and hospitals that they are in-network</li>
                                <li>read the <a href={benefits_url} target="_blank" className="text-blue-500 underline">summary of benefits</a> which explains how your plan covers the cost of different services</li>
                            </ul>
                        </div>

                    </div>
                    <a href={enrollLink} target="_blank">
                        <button 
                            className="px-24 rounded text-white text-2xl py-4 bg-lumos-blue mt-14"
                        >
                            Enroll on HealthSherpa
                        </button>
                    </a>
                    <p className='mt-2 text-slate-500'>Once on HealthSherpa, select "add to cart, and then "start application".</p>
                </div>
            }
        </div>
    )
}
