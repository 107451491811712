import classNames from 'classnames'
import { useLocation} from 'react-router-dom'

/**
 * Defines the Application Progress UI for the Application form.
 * @returns {JSX}
 */

const routeMapping = {
  'specialEnrollment': 'coverage'
  , 'location' : 'about'
  , 'applicants' : 'about'
  , 'household' : 'savings'
  , 'subsidy' : 'savings'
  , 'providers' : 'coverage'
  , 'drugs' : 'coverage'
  , 'usageExplainer': 'usage'
  , 'healthStatus' : 'usage'
  , 'preferences' : 'other'
  , 'submit' : 'other'
}

export default function Progress(){
  
  const location = useLocation()
  
  const currentRoute = location.pathname.split('/')[2]
  const currentStep = routeMapping[currentRoute]
  

 
  function navTitleClass(step) {
    return classNames('progress-bar-text', {
      'text-lumos-inactive': currentStep !== step
    })
  }

  function navBarClass(step) {
    return classNames('border-2 mt-2', {
      'bg-lumos-blue text-lumos-blue border-lumos-blue': currentStep === step,
      'bg-lumos-inactive border-lumos-inactive': currentStep !== step
    })
  }

  return (
      <div className="navigation flex grid grid-cols-5 progress-bar-spacing max-w-3xl mx-auto">
        <div className="step-status text-center">
          <a className={navTitleClass('about')}>About You</a>
          <hr className={navBarClass('about')}/>
        </div>
        <div className="step-status text-center">
          <a className={navTitleClass('savings')}>Savings</a>
          <hr className={navBarClass('savings')}/>
        </div>
        <div className="step-status text-center">
          <a className={navTitleClass('coverage')}>Coverage</a>
          <hr className={navBarClass('coverage')}/>
        </div>
        <div className="step-status text-center">
          <a className={navTitleClass('usage')}>Usage</a>
          <hr className={navBarClass('usage')}/>
        </div>
        <div className="step-status text-center">
          <a className={navTitleClass('other')}>Other</a>
          <hr className={navBarClass('other')}/>
        </div>
      </div>
  )
}
