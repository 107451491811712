import React from 'react';
import yt_icon from '../assets/icons/youtube.png'
import { useState } from 'react';

export default function YoutubePlayer(props) {

    // display as help icon first, then play icon on hover
    // const [isHover, setIsHover] = useState(false)
    const [isDisplayVideo, setIsDisplayVideo] = useState(false)

    const player = (
        <div className="youtube-player">
            <iframe 
                id="player" 
                type="text/html" 
                style={{width: '100%', height: '100%'}}
                width="640" 
                height="390"
                allowFullScreen
                src="https://www.youtube.com/embed/qRq4ZvOo6K0?controls=1"
                frameborder="0">
            </iframe>
            <div 
                className='close flex justify-center'
                onClick={() => {
                    setIsDisplayVideo(false)
                    // setIsHover(false)
                }}
            >
                <i class="fa-solid fa-times"></i>
            </div>
        </div>
    )


    const ytIcon = (
        <img src={yt_icon} alt='youtube'/>
    )

    const helpIcon = (
        <div className='help flex space-around items-center text-center'>
            <i className='fa-solid fa-circle-info text-lumos-blue mr-2 text-xl'></i>
            <span className='leading-4'>Explain graph</span>
        </div>
    )
    const popupBubble = (
        <div 
            className="popup-dialog rounded-md bg-lumos-light p-2 cursor-pointer"
            onClick={() => setIsDisplayVideo(true)}
            // onMouseEnter={() => setIsHover(true)}
            // onMouseLeave={() => setIsHover(false)}
        >
            {/* {isHover ? ytIcon : helpIcon} */}
            {helpIcon}
        </div>
    )

    return (
        isDisplayVideo? player: popupBubble
    )
}