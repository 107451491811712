import React, { useEffect, useState, createContext } from 'react'
import { useSearchParams, useLocation, Outlet, useNavigate } from "react-router-dom"

import {getPlanRecs} from '../../utils/apis.js'
import { ResultsContext, ApplicationContext } from './Contexts.js'
import { getStateSupportType } from '../../utils/utility.js'
import Confetti from 'react-confetti';

// Manages api call to get plan results and passes down results in order to be read.

export default function ResultsLayout(props){

  const [application, setApplication] = useState()
  const [results, setResults] = useState()
  const [, setSearchParams] = useSearchParams()
  const [width, setWidth] = useState(window.innerWidth);

  // should only ever arrive at this location with search params
  const location = useLocation()

  let navigate = useNavigate()
  if (!application) {
    // navigate('loading')
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;

  // on first load, read in application from search parameters
  useEffect( () => {
    const sp = new URLSearchParams(location.search)
    const appVar = JSON.parse(sp.get('application'))
    setApplication( appVar )
    // setSearchParams("")
  }, [])

  // once application is updated, fetch results, can later check app_id and see if results are available in local storage
  useEffect( () =>{
    if (application) {
      if ((application.source=="email") && (!isMobile)) {
        navigate('./loadingreturnuser')
        getPlanRecs(application, true)
          .then( data => {
            console.log(JSON.stringify(data) )
            data['state'] = application.location.state // or have the api return state
            data['stateSupport'] = getStateSupportType(application.location.state)
            setResults(data)
            navigate('./shop/summary')
          } )
      } else if (isMobile) {
        //do nothing
      } else {
        navigate('./loading')
        getPlanRecs(application, false)
          .then( data => {
            console.log(JSON.stringify(data) )
            data['state'] = application.location.state // or have the api return state
            data['stateSupport'] = getStateSupportType(application.location.state)
            setResults(data)
            navigate('./loadingcomplete')
            setTimeout(() => {
              navigate('./shop/summary');
            }, 5000);
          }  )    
      }
    }
  },[application])

  return (
    <div>
        <div className="results-mobile application-title-text">
            <b>Congratulations, your results are ready!</b> <br></br><br></br>
            Your results and recommendations contain charts and figures best viewed on a larger screen.<br></br><br></br>
            Access your results by clicking the link we've emailed you using a laptop 💻 or desktop screen 🖥️.
            <Confetti recycle={false} numberOfPieces={3000}/>
        </div>
        <div className="main results-web form w-full mx-auto my-10 max-w-6xl">
          <ResultsContext.Provider value={results}>
            <ApplicationContext.Provider value={application} >
              <div className='flex justify-center'>
                <Outlet />
              </div>
            </ApplicationContext.Provider>
          </ResultsContext.Provider>
        </div>
    </div>
  )
}
