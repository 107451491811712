import React, { useEffect, useRef, useState } from 'react'
import { useOutletContext, useNavigate, Link, useLocation } from "react-router-dom"
import { specialUsageRequest } from '../../utils/apis';
import { isEmail } from '../../utils/utility.js'
import axios from 'axios'

import _ from 'lodash'
import Select from 'react-select'
import classNames from 'classnames'

import {getRoute} from './ApplicationLayout'


const options = [
  { value: 1, label: '1 - Excellent', simplelabel: 'Excellent' }
  , { value: 2, label: '2 - Very Good', simplelabel: 'Very Good' }
  , { value: 3, label: '3 - Good', simplelabel: 'Good' }
  , { value: 4, label: '4 - Fair', simplelabel: 'Fair' }
  , { value: 5, label: '5 - Poor', simplelabel: 'Poor' }
]

export default function HealthStatus(props){

  const location = useLocation()

  const [loading, setLoading] = useState(false);
  const [isSent, setisSent] = useState(false);

  const {application, setApplication} = useOutletContext()

  const [form, setForm] = useState({
      full_name: ""
    , email: application.email || ""
    , additional_comments: ""
  })

  function handleFormChange(field, value){
    setForm( oldForm => {
        const newForm = {...oldForm}
        newForm[field] = value
        return newForm
    })
  }

  // initialize selection status -- ['standard', 'custom']
  const [usageEstimation, setUsageEstimation] = useState(application.usageEstimation || null);
  useEffect(() => {
    setApplication( oldApp => ({...oldApp, usageEstimation}))
  }, [usageEstimation])

  // initialize healthStatuses
  const [healthStatuses, setHealthStatuses] = useState(
    { primary: application.primary.healthStatus || null
      , spouse: (application.spouse && application.spouse.healthStatus) || null
      , dependents: application.dependents.map( d => d.healthStatus )
    }
  )
  // push healthStatuses up to application on change
  useEffect(() => {
    setApplication( oldApp => {
      const newApp = {...oldApp}

      newApp.primary = {...oldApp.primary, healthStatus: healthStatuses.primary}
      if (oldApp.spouse) {
        newApp.spouse = {...oldApp.spouse, healthStatus: healthStatuses.spouse}
      }
      newApp.dependents = oldApp.dependents.map( (d, i) => ({...d, healthStatus:healthStatuses.dependents[i]}) )

      return newApp
    })
  }, [healthStatuses])

  const [errors, setErrors] = useState({overall:null, primary:null, spouse:null, dependents:[]});
  const [formerrors, setFormErrors] = useState({});

  const fullNameRef = useRef(null);
  const emailRef = useRef(null);

  let navigate = useNavigate()


  function nextRoute(){
    if (hasError()) {
      return
    }
    navigate(getRoute(application.planYear, location))
  }

  function prevRoute(){
    navigate(getRoute(application.planYear, location, false))
  }

  function submitForm() {
    if (hasFormError()) {
      return
    }

    setLoading(true)

    specialUsageRequest(form, JSON.stringify(application))
        .then(response => {
            if(response.status === 200) {
                setisSent(true)
                setLoading(false)
            }
        })
  }

  function hasFormError() {

    let isError = false
    const newFormErrors = {}

    if(usageEstimation == 'custom' && (form.full_name === undefined || form.full_name === null || form.full_name === "")){
      newFormErrors.full_name = ["Please provide your name"]
      isError = true
      fullNameRef.current.scrollIntoView({ behavior: "smooth", block:"start"});
   }

    if(usageEstimation == 'custom' && (form.email === null || form.email === "" || !form.email || !isEmail(form.email))){
      newFormErrors.email = ["Please provide an email"]
      isError = true
      emailRef.current.scrollIntoView({ behavior: "smooth", block:"start"});
    }

    setFormErrors(newFormErrors)

    return isError
  }

  // check that each family member has a health status selected
  function hasError() {
    let isError = false
    const standardErrorMessage = "please select a health status"
    const selectionIncompleteError = 'please select a usage option'
    const newErrors = {}

    if(!usageEstimation) {
      newErrors.overall = selectionIncompleteError
      isError=true
    }

    if (!healthStatuses.primary){
      newErrors.primary = standardErrorMessage
      isError = true
    }
    if (application.spouse && !healthStatuses.spouse) {
      newErrors.spouse = standardErrorMessage
      isError = true
    }
    newErrors.dependents = healthStatuses.dependents.map( d => {
      if (!d) {
        isError = true
        return standardErrorMessage
      }
    })

    setErrors(newErrors)

    return isError
  }



  return (
    <div className="health-status-component">
      <div className="title info mt-10 text-center">
          <h2 className="application-title-text">Let's understand your usage. <br></br> Do you expect high usage next year?</h2>
            <p className="application-subtitle-text mt-5 household-subtitle-spacing">Your answer won't impact your premiums, but allows us to estimate the <a href='https://www.blog.lumos-health.com/blog/lumos-true-cost' target="_blank" className='underline'>true cost</a> of each plan.</p>
      </div>
      <div className='flex flex-col text-center'>
      <div className="flex flex-row justify-center mt-10">
          <button
              onClick={() => {
                setUsageEstimation('custom')
              }}
              className={classNames('health-status-buttons health-status-yes border border-lumos-blue rounded-lg'
              , {'bg-lumos-blue text-white font-bold':(usageEstimation == 'custom')})}
            >
              Yes
          </button>
          <button
              onClick={() => {
                setUsageEstimation('standard')
              }}
              className={classNames('health-status-buttons health-status-no border border-lumos-blue rounded-lg'
              , {'bg-lumos-blue text-white font-bold':(usageEstimation === 'standard')})}
            >
              No
          </button>
      </div>
      <div>
        <span className="text-red-500 block mt-4">{errors.overall}</span>
      </div>
      </div>

      { usageEstimation === 'standard' &&
      <>

        <h3 className='italic text-center mt-4'>We'll model your usage according to <span className='font-bold'>people like you</span>.</h3>

        <div className="step px-5">
          {/* top info */}
          <div className="title info mt-16 text-center">
            <h2 className="application-title-text2">How would you rate your current health?</h2>
          </div>

          {/* select health status for each applicant */}
          <div className='flex flex-col items-center'>

            <PickHealthStatus
              key= "primary"
              error = {errors.primary}
              relationship= "primary"
              person= {application.primary}
              healthStatus= {healthStatuses.primary}
              setHealthStatus= { (newStatus) => setHealthStatuses( old => ({...old, primary:newStatus}) )}
            />

            {application.spouse &&
              <PickHealthStatus
                key= "spouse"
                error = {errors.spouse}
                relationship= "spouse"
                person= {application.spouse}
                healthStatus= {healthStatuses.spouse}
                setHealthStatus= { (newStatus) => setHealthStatuses( old => ({...old, spouse:newStatus}) )}
              />
            }

            {application.dependents.map( (dep, i) =>
              <PickHealthStatus
                key= {i}
                idx = {i}
                error = {errors.dependents[i]}
                navigate={navigate}
                relationship= "dependent"
                person= {dep}
                healthStatus= {healthStatuses.dependents[i]}
                setHealthStatus= { (newStatus) => setHealthStatuses( old => {
                    const newStatuses = {...old}
                    newStatuses.dependents[i] = newStatus
                    return newStatuses
                  }
                )}
              />
            )}
          </div>
          {/* navigation */}
          <div className="actions flex items-center justify-center mt-20">
            <button
              onClick={ prevRoute }
              className="navigation-button-back"
            >
              Back
            </button>
            <button
              onClick={nextRoute}
              className="navigation-button-next"
            >
              Next
            </button>
          </div>
        </div>
      </>
      }

{( usageEstimation === 'custom' && (!isSent)) &&

    <>

    <h3 className='italic text-center mt-4'>We'll model your usage with a <span className='font-bold'>custom scenario</span> or <span className='font-bold'>your claims from a prior year</span>.</h3>

    <div className="step px-5">

        {/* top info */}
        <div className="title info mt-12 text-left">
          <h2 className="application-title-text2">Let's work 1-on-1 to find plans with a low total cost for your unique usage! Provide your contact info for us to reach out.</h2>
        </div>


        <div className="flex flex-col">
          <div className="field mt-6">
              <label className="text-lumos-blue font-semibold">Full Name</label>
              <input
                  ref={fullNameRef}
                  onChange={(e) => handleFormChange('full_name', e.target.value)}
                  type="text" placeholder="Full Name"
                  className="mt-2 w-full border border-lumos-blue block py-3 px-3 rounded-lg"
              />
              <span key={'errors-full_name'} className="text-red-500 mt-2 block ml-1">{formerrors.full_name}</span>
            </div>

          <div className="field mt-6">
              <div className="text-lumos-blue font-semibold">Email</div>
              <input
                  ref={emailRef}
                  type="text"
                  onChange={(e) => handleFormChange('email', e.target.value)}
                  value={form.email}
                  placeholder="Email"
                  className="mt-2 w-full border border-lumos-blue block py-3 px-3 rounded-lg"
              />
              <span key={'errors-email'} className="text-red-500 mt-2 block ml-1">{formerrors.email}</span>
            </div>

            <div className="field mt-6">
                <label className="text-lumos-blue font-semibold">
                    Additional Comments
                    <span className="text-xs ml-1">(Optional)</span>
                </label>
                <textarea
                    type="text"
                    onChange={(e) => handleFormChange('additional_comments', e.target.value)}
                    placeholder="Additional Comments"
                    className="mt-2 w-full border border-lumos-blue block py-3 px-3 rounded-lg"
                />
              </div>
        </div>

      {/* navigation */}
      <div className="actions flex items-center justify-center mt-12">
        <button
          onClick={() => submitForm()}
          className="navigation-button-next"
        >
          <span>Submit</span>
          {loading &&
            <span>
                <img className="inline w-8 ml-3" src="/loading.gif" alt=""/>
            </span>
            }
        </button>
        </div>
      </div>
      </>
    }

        {/* navigation */}
        {((!usageEstimation)) &&
        <div className="actions flex items-center justify-center mt-80">
          <button
            onClick={ prevRoute }
            className="navigation-button-back"
          >
            Back
          </button>
          <button
            onClick={nextRoute}
            className="navigation-button-next"
          >
            Next
          </button>
          </div>
          }

      {( (isSent) && (usageEstimation === 'custom') ) &&
        <div className="thanks mt-24 text-center">
            <div>
                <h1 className="text-lumos-green text-3xl px-20 py-0">Your request has been received!</h1>
                <p className='text-xl text-lumos-gray-dark'>We will be in touch shortly.</p>
            </div>
        </div>
      }
    </div>
  )
}


function PickHealthStatus(props) {
  const colorPalette = ['#1F7401', '#054075', '#EDA00C','#DC9595', '#AC0202']
  const {relationship, error, person, healthStatus, idx = null, setHealthStatus} = props
  const {age, sex} = person

  const [apiData, setApiData] = useState(null)

  // request cohort selection data
  useEffect( () => {
    const url = process.env.REACT_APP_API_URL+'/cohortselection'
    axios.post(url, {age, sex})
      .then(res => {
        setApiData(res.data)
        // console.log(res.data)
      })
    }
    , [age, sex]
  )

  const [usage, setUsage] = useState()
  useEffect( () => {
    console.log("update usage")
    // console.log(healthStatus)
    if (apiData && healthStatus) {
      setUsage(apiData.usage[healthStatus-1])
    }
  }, [healthStatus, apiData])

  let titleStr = "You"
  if (relationship === "spouse"){
    titleStr = "Spouse"
  } else if (relationship === "dependent") {
    titleStr = "Dependent"
  }

  return (
    apiData ?
      <div className="rounded-lg mt-8 border border-lumos-blue p-5 justify-left w-full">
        <h2 className="text-lg text-lumos-blue font-semibold">
          {titleStr}
        </h2>

        <div className="health-status-arrange mt-4">
          <div className="flex flex-row">
          <div className="age text-center">
            <p className="text-lg font-semibold text-left">Age</p>
            <div className="border border-lumos-blue w-20 px-3 py-3 text-left rounded-lg mt-2 bg-lumos-back">{person.age}</div>
          </div>
          <div className="age text-center ml-14">
            <p className="text-lg font-semibold text-left">Sex</p>
            <div className="border border-lumos-blue w-20 px-3 py-3 text-left rounded-lg mt-2 bg-lumos-back">{person.sex === "M" ? "Male" : "Female"}</div>
          </div>
          </div>
          <div>
          <div className="text-left health-status-selector">
              <p className="font-semibold inline text-lg text-center"> Health Status</p>
              <Select
                defaultValue={ healthStatus ?
                  {value: healthStatus, label: options[healthStatus-1].label}
                  : null
                }
                onChange={(option) => {
                  setHealthStatus(option.value)
                  setUsage(apiData.usage[option.value-1])
                }}
                className="mt-2 text-left rounded-lg"
                options={options}
                styles={
                  {valueContainer: (provided, state) => ({
                    ...provided,
                    height: '50px',
                  })}
                }
              />
              <span className="text-red-500 mt-2 block ml-1">{error}</span>
          </div>
          </div>

        </div>



        {/* feedback on health status choice */}
        <div className= "mt-6 flex-col">
          {usage &&
            <>
              <p>{Math.round(usage.share*100)}% of {sex === 'M'? 'males' : 'females'} the same age select this rating.</p>

              {/* create dist bar */}
              <div className='h-5 rounded bg-slate-200 flex'>
              {apiData.usage.map( (item, i) => (
                <div
                  className='h-full rounded overflow-visible'
                  style={{
                    width: Math.round(item.share*100)+'%'
                    , backgroundColor: (i === healthStatus-1) ? colorPalette[i] : 'inherit'}}
                >

                  </div>
              )
                )
              }
              </div>

              <div className='mt-4'>
                <p>A typical year for {sex === 'M'? 'males' : 'females'} the same age in {options[healthStatus-1].simplelabel.toLowerCase()} health is:</p>
                <ul className='list-inside list-disc ml-3'>
                  <li>Doctor Visits: {getRangeValue(usage, 'doctor_visits')}</li>
                  <li>Labs/Imaging Visits: {getRangeValue(usage, 'labs_imaging')}</li>
                  <li>Prescription Fills: {getRangeValue(usage, 'prescription_fills')}</li>
                </ul>
              </div>
            </>
          }

        </div>
      </div>

    : null
  )
}

function getRangeValue(item, field){
  return item[field].length > 1 ? Math.round(item[field][0]) + "-" + Math.round(item[field][1]) : "~"+ Math.round(item[field][0])
}
