import React, { useEffect, useState } from 'react'
import { NumericFormat } from "react-number-format";
import { useNavigate, useOutletContext, useLocation } from "react-router-dom"

import info from '../../assets/icons/info.svg'
import { isNumber } from 'lodash';

import {getEstimates} from '../../utils/apis'
import {getRoute} from './ApplicationLayout'

const MAX_HOUSEHOLD_SIZE = 20


/**
 * Defines subsidy UI
 * @param {*} props 
 * @returns {JSX}
 */
export default function Household(props){

  const {application, setApplication} = useOutletContext()
  const location = useLocation()

  const minPeople = 1 + (application.spouse? 1:0) + (application.dependents.length)
  const [householdSize, setHouseholdSize] = useState( 
    (application.householdSize 
      && application.householdSize >= minPeople 
      && application.householdSize <= MAX_HOUSEHOLD_SIZE
    ) ?
    application.householdSize
    : minPeople
  )
  const [income, setIncome] = useState(application.income ? application.income.toString() : "")
  
  const [errors, setErrors] = useState({})


  useEffect( 
    () => setApplication( (old) => ({...old, householdSize, income: parseInt(income.replace(/\,/g,'')) }) )
    , [householdSize, income]
  )

  let navigate = useNavigate()

  function nextRoute(){
    if ( hasError() ) {
      return
    }
    getEstimates(application)
      .then( (response) => {
        setApplication( (old) => ({...old, ...response}) )
        navigate(getRoute(application.planYear, location))
    })
    
  }

  function previousRoute(){
    navigate(getRoute(application.planYear, location, false))
  }


  // controls +/- button clicking for household size
  function handlePeopleChange(operation){
    if(operation === 'inc' && householdSize < MAX_HOUSEHOLD_SIZE){
        setHouseholdSize(parseInt(householdSize) + 1)
    }
    if(operation === 'dec' && householdSize > minPeople){
      setHouseholdSize(parseInt(householdSize) - 1)
    }
    if (!isNumber(householdSize)) {
      setHouseholdSize(minPeople)
    }
  }

  function hasError() {
    setErrors({income: []})

    const parsedIncome = parseInt( income.replace(/\,/g,'') )
    
    if(parsedIncome < 0 || parsedIncome > 1000000 || isNaN(parsedIncome) ){
      setErrors( old => ({...old, income:[...old.income, 'Enter a valid income.']}) )
    } else {
      return false
    }
    return true
  }


  


  return (

    <div className="step household-component">

      <div className="mt-10 text-center">
        <h2 className="application-title-text">Next, tell us about your household income.</h2>
        <p className="application-subtitle-text household-subtitle-spacing">We need this information to estimate your savings.</p>
      </div>

      <div className="inputs household-inputs-spacing my-10">
        <div className="flex">
          <p className="household-inputs-text">How many people are in your household?</p>
          <div className="tooltip-container ml-2">
              <div className="tooltip">
                  <span className="household-info-icon"><img src={info} alt="Info"/></span>
                  <i className="icon ri-arrow-right-line" />
                <span className="tooltip-drop tooltip-left">
                Enter the number of people you put on your tax return, even if they are not applying for coverage.
                </span>
              </div>
          </div>
        </div>
        <div className="input flex mt-2">
          {/* consider changing so that only +/- buttons can be used, no direct input */}
          <input value={householdSize} onChange={(e) => setHouseholdSize(e.target.value)} min={minPeople} max="20" type="number" className="w-full border border-lumos-blue block py-2 px-3 rounded-l-lg border-r-0"/>
          <button onClick={() => handlePeopleChange('dec')} className="border border-lumos-blue px-5 bg-lumos-gray text-xl">-</button>
          <button onClick={() => handlePeopleChange('inc')} className="rounded-r-lg border border-l-0 border-lumos-blue px-5 bg-lumos-gray text-xl">+</button>
        </div>
        <div className="input flex">
          {errors && errors.people && 
            errors.people.map((error, index) =>
              <span key={'errors-people-'+index} className="text-red-500 mt-2 block ml-1">{error}</span>
            )
          }
        </div>

        <div className="flex mt-10">
          <p className="household-inputs-text">Estimate your {application.planYear} household income, before taxes.</p>
          <div className="tooltip-container ml-2">
              <div className="tooltip">
                  <span className="household-info-icon"><img src={info} alt="Info"/></span>
                  <i className="icon ri-arrow-right-line" />
                <span className="tooltip-drop tooltip-left">
                Enter the total taxable income of your tax household. This includes anyone you're claiming as a dependent or filing with, <b>even if they are not applying for coverage</b>. 
                <br></br><br></br>Your income determines if and how much you're eligible for in government subsidies. A guess is okay for now, but a more accurate estimate will give you a better indication of your share of the cost.
                </span>
              </div>
          </div>
        </div>
        <div className="input flex mt-2">
          <span className="border flex items-center border-lumos-blue px-4 bg-lumos-gray text-xl rounded-l-lg">$</span>
          <NumericFormat
            type="text"
            value={income}
            onChange={(e) => setIncome( e.target.value )}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            decimalScale={0}
            allowNegative={false}
            className="w-full border border-lumos-blue block py-2 px-3 rounded-r-lg border-l-0"
          />
        </div>
        <div className="input flex">
          {errors && errors.income && 
            errors.income.map((error, index) =>
              <span key={'errors-income-'+index} className="text-red-500 mt-2 block ml-1">{error}</span>
            )
          }
        </div>
      </div>

      {/** navigation buttons */}
      <div className="actions navigation-spacing mt-20">
        <button onClick={() => previousRoute(navigate)} className="navigation-button-back">
          Back
        </button>
        <button onClick={() => {
            nextRoute()
          }} className="navigation-button-next">
          Next
        </button>
      </div>
    </div>

  )
}

