import greenCheck from '../../assets/icons/green-check.svg'
import redCross from '../../assets/icons/red-cross.svg'

import React, { useState, useContext, useRef, useEffect } from 'react'

import { ResultsContext, ApplicationContext } from './Contexts'

import { getLogo, getNetPremium, commaFormat } from '../../utils/utility'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames';


export default function PlanStub(props) {
    const {plan
      , subsidy
      , isProviders
      , isDrugs
      , isSelectToCompare = false
      , isShowEnrollButton = true
      , isExpanded=false
      , onEnroll=false
      , plansToCompare=[]
      , setPlansToCompare=null
    } = props
    const {stateSupport} = useContext(ResultsContext)
    const {pcpCostTolerance} = useContext(ApplicationContext)
    const [isHover, setIsHover] = useState(isExpanded)

    const [isCompareSelected, setIsCompareSelected] = useState(plansToCompare.includes(plan.id))
    const isDisableAddToCompare = plansToCompare.length >= 4 && !isCompareSelected

    useEffect(() => {
      setIsCompareSelected(plansToCompare.includes(plan.id))
    }
      , [plansToCompare]
    )

  
    const navigate = useNavigate()
  
    return (
      <div className='flex items-start'>
        <div className={classNames('pt-4',
          {'tags-container w-1/6 flex flex-col items-end':!onEnroll, 
          'tags-container-enroll w-1/12 flex flex-col items-end':onEnroll})}
          >
            {plan.tags.map(tag => 
                <div 
                  className={classNames(
                    {'plan-stub-tag-enroll':onEnroll,
                    'plan-stub-tag':!onEnroll})}>
                    <PlanTag tag={tag}/>
                </div> 
            )}
        </div>
        <div 
          className= {classNames('mt-4 shadow',
            {'plan-stub-no-hover hover:bg-sky-50 hover:shadow-lg': !isExpanded,
             'plan-stub-no-hover-enroll': onEnroll})} 
          onMouseEnter={()=>setIsHover(true)}
          onMouseLeave={()=>setIsHover(isExpanded? true : false)}
        >
  
          <div className='plan-name-container flex justify-left items-center px-2 py-1 border-b border-black'>
              <div className='w-1/12'>
                  <img className="max-h-5" src={getLogo(plan.issuer)} alt={plan.issuer}/> 
              </div>
              <p className="ml-3 plan-stub-plan-name text-sm text-lumos-blue line-clamp-1">{plan.plan_name}</p>
              {isSelectToCompare &&
                <label
                  htmlFor={'compare_'+plan.id}
                  className={classNames(
                    'input cursor-pointer flex items-center ml-auto rounded-lg',
                  )}
                >
                  <p className={isDisableAddToCompare && 'text-lumos-gray'}>Compare</p>
                  <input
                    className='mx-1'
                    id={'compare_'+plan.id}
                    type="checkbox"
                    checked={isCompareSelected}
                    name={'compare_'+plan.id}
                    disabled={isDisableAddToCompare}
                    onChange={(e) => {
                      setIsCompareSelected((old) => !old)
                      if(e.target.checked){
                        setPlansToCompare((old) => [...old, plan.id])
                      } else {
                        setPlansToCompare((old) => old.filter(item => item !== plan.id))
                      }
                    }}
                  />
                </label>
              }
          </div>
  
          <div className="main-info flex">
  
            <div className={`details w-4/5 grid grid-cols-${(isDrugs||isProviders)?'4':'3'} gap-x-2 py-2 px-2`}>
              
              <div className='premiums flex flex-col items-center'>
                <h2 className='text-base mt-2 text-slate-500 underline'>Premium</h2>
                <span className='mt-2 text-2xl'>${commaFormat(getNetPremium(plan.premium,subsidy))}/mo</span>
                {subsidy > 0 &&
                <span className='text-xs text-lumos-red line-through'>${commaFormat(plan.premium)} w/o subsidy</span>
                }
              </div>
  
              <div className='true-cost flex flex-col items-center'>
                <h2 className='text-base mt-2 text-slate-500 underline'>True Cost</h2>
                <span className='mt-3.5 text-2xl font-semibold'>${commaFormat(plan.true_cost/12)}/mo</span>
              </div>
  
              {(isDrugs||isProviders) &&
                <div className='coverage flex flex-col items-center'>
                  <h2 className='text-base mt-2 text-slate-500 underline'>Coverage</h2>
                  {isDrugs &&
                    <div className='w-full flex px-7 mt-2'>
                      <span className='text-sm mr-auto'>Drugs:&nbsp;</span>
                      <span className='text-sm'>
                      {plan.drug_coverage.filter(item => item.is_covered).length} of {plan.drug_coverage.length}
                      </span>
                    </div>
                  }
                  {isProviders &&
                    <div className='w-full flex px-7 mt-2'>
                      <span className='text-sm mr-auto'>Providers:&nbsp;</span>
                      <span className='text-sm'>
                        {plan.provider_coverage.filter(item => item.is_covered).length} of {plan.provider_coverage.length}
                      </span>
                    </div>
                  }
                </div>
              }
  
              <div className='coverage flex flex-col items-center'>
                <h2 className='text-base mt-2 text-slate-500 underline'>Other Details</h2>
  
                {/* double div allow for centering left aligned text */}
                <div className='flex space-around'>
                  <div>
                    {/* <div className='flex w-full mt-2'>
                      <img src={plan.specialist_referral_required == false ? greenCheck : redCross} alt="checkmark" className='h-5'/>
                      <span className='text-sm ml-2 '>No referral required</span>
                    </div> */}
                    {(pcpCostTolerance>-1) && <div className='flex w-full mt-3'>
                      <img 
                        className='h-5'
                        alt="checkmark" 
                        src={
                          plan.pcp_cost > (pcpCostTolerance) ? redCross
                          : greenCheck
                        } 
                      ></img>
                      <span className='text-sm ml-2 '>Doctor visit &lt; ${pcpCostTolerance}</span>
                    </div>
                      }
                  </div>
                </div>
  
              </div>
  
            </div>
  
            <div className='actions w-1/4 flex flex-col px-10 py-3'>
              {/* would not show enroll button if already at enroll page */}
              {isShowEnrollButton && 
                <>
                {stateSupport == "supported" &&
                    <button
                        className='plan-stub-enroll-button w-full text-white rounded-lg px-4 py-2.5 text-base'
                        onClick={() => navigate(`/results/enroll/${plan.id}`)} 
                    >
                        Enroll
                    </button>
                }
                {stateSupport == "partial" &&
                    <div className="tooltip-container w-full">
                        <div className="tooltip w-full">
                            <button 
                            className="w-full bg-gray-400 text-white rounded-lg px-4 py-2.5 text-base cursor-not-allowed" 
                            disabled
                            >
                            Enroll
                            </button>
                        <span className="tooltip-drop tooltip-bottom">
                            We are not yet able to process enrollments in your state. Until then, you can enroll directly on www.healthcare.gov.
                        </span>
                        </div>
                    </div>
                }
                </>
              }
              <button
                  className='plan-stub-view-button w-full text-white rounded-lg px-4 py-2.5 mt-2 text-base'
                  onClick={() => navigate(`/results/view/${plan.id}`)} 
              >
                  View Details
              </button>
            </div>
          </div>
  
          {isHover &&
          <>
            <div className='cost-breakdown border-t border-slate-400'>
              <h2 className='text-lg text-lumos-blue border-b border-slate-400 px-4 py-1'>Cost Breakdown</h2>
              <div className='inline-math-grid place-items-center pb-5'>
                <h2 className='text-sm text-slate-500'>True Cost</h2>
                <h2 className='col-start-3 text-sm text-slate-500'>Premium</h2>

                  <h2 className='col-start-5 text-sm text-slate-500'>Subsidy</h2>
                  <h2 className='col-start-7 text-sm text-center text-slate-500'>Est. Out-of-Pocket Expenses</h2>

             
                <span className='text-2xl font-semibold'>${commaFormat(plan.true_cost/12)}/mo</span>
                <span className='text-2xl'>=</span>
                <span className='text-2xl text-slate-500'>${commaFormat(plan.premium)}/mo</span>
                {subsidy >= 0 &&
                  <>
                    <span className='text-2xl'>-</span>
                    <span className='text-2xl text-lumos-green'>${commaFormat(subsidy)}/mo</span>
                  </>
                }
                <span className='text-2xl'>+</span>
                <span className='text-2xl text-slate-500'>${commaFormat(plan.oop_mean/12)}/mo</span>
              </div>
            </div>
  
            {(isDrugs||isProviders) && 
              <div className='coverage border-t border-slate-400'>
                <h2 className='text-lg text-lumos-blue border-b border-slate-400 px-4 py-1'>Coverage Breakdown</h2>
  
                <div className='coverage-grid grid grid-cols-2 gap-x-2 mt-2 ml-2'>
                  {isDrugs &&
                  <div>
                    <h2 className='text-base text-slate-500 ml-3'>
                      Drugs Covered: {plan.drug_coverage.filter(item => item.is_covered).length} of {plan.drug_coverage.length}
                    </h2>
                    <div className='grid grid-cols-2 self-start gap-x-2 ml-3 mt-3 mb-3'>
                      {plan.drug_coverage.map(d =>
                        <div className='flex'>
                          <img src={d.is_covered ? greenCheck : redCross} alt="checkmark" className='h-4'/>
                          <span className='text-xs ml-1 mb-2'>{d.name}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  }
  
                  {isProviders &&
                  <div>
                    <h2 className='text-base text-slate-500'>
                      Providers Covered: {plan.provider_coverage.filter(item => item.is_covered).length} of {plan.provider_coverage.length}
                    </h2>
                    <div className='grid grid-cols-2 self-start gap-x-4 mt-3 mb-3'>
                      {plan.provider_coverage.map(d =>
                        <div className='flex'>
                          <img src={d.is_covered ? greenCheck : redCross} alt="checkmark" className='h-4'/>
                          <span className='text-xs ml-1 mr-3 mb-2'>{d.name}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  }
  
                </div>
                  
              </div>
            }
          </>
          }
        </div>
      </div>
    )
  }
  
function PlanTag(props){
    const application = useContext(ApplicationContext)
    const {tag} = props
  
    const TAG_LKP = {
        'best cost': {'text':'Best Value', 'info':'Best combination of true cost and risk-protection.'}
        , 'best coverage': {'text':'Best Coverage', 'info': 'Best true cost and risk-protection among plans that cover the most possible listed providers'}
        , 'best HSA eligible': {'text':'HSA Eligible', 'info':'Best true cost and risk-protection among plans that allow you to contribute to an HSA (Health Savings Account).  This is a tax favored savings account that allows you to reduce your tax bill by as much as $1.8k for individuals and $3.7k, depending on your tax situation.'}
        , 'best no referral required': {'text': 'No Referral Required', 'info':'Best true cost and risk-protection among plans that allow you to see a specialist before first consulting your primary care doctor.  Most HMO plans will require you to get a referral before you can see a specialist.'}
        , 'best accessible PCP cost': {'text':'Accessible Doctor Fee', 'info':`Best true cost and risk-protection among plans where the cost to see a doctor is at most $${application.pcpCostTolerance}.`}
    }
  
    const myTag = TAG_LKP[tag.label]
  
    return (
    <div key={tag.label} className='tooltip-container'>
        <div className="tooltip">
            <span className="p-1 text-xs bg-lumos-tags">{myTag.text}</span>
            <span className="tooltip-drop tooltip-bottom text-base font-medium">{myTag.info}</span>
        </div>
    </div>
  
    )

  }
