import React, {useContext, useEffect, useState} from 'react'
import { Outlet, useLocation, useNavigate} from "react-router-dom"
import classNames from 'classnames'
import info from '../../assets/icons/info.svg'
import { ResultsContext } from './Contexts'
import { getLogo } from '../../utils/utility'
import filter from '../../assets/icons/filter_lines.svg'

import _ from 'lodash'


export default function ShopLayout(props){

  // const results = useContext(ResultsContext)

  const location = useLocation()
  const currentRoute = location.pathname.split('/')[3]

  const results = useContext(ResultsContext)

  //store and pull the variable from session Storage 
  const LastPageStatus = JSON.parse(sessionStorage.getItem('isRecommendedLastPage'));
  const [isRecommendedPlans, setIsRecommendedPlans] = useState(LastPageStatus ?? true)
  const [isExpandFilters, setIsExpandFilters] = useState(false)

  const FilterStatus = JSON.parse(sessionStorage.getItem('filterApplied'));
  const [isFilterApplied, setIsFilterApplied] = useState(FilterStatus ? FilterStatus.filterApplied || false : false)

  const navigate = useNavigate()
  const [displayedPlanIds, setDisplayedPlanIds] = useState(results.recommended_plan_ids)

  useEffect(() => {
    if (isRecommendedPlans) {
      setDisplayedPlanIds(results.recommended_plan_ids)
      sessionStorage.setItem('isRecommendedLastPage', JSON.stringify(true));
    } else if (!isFilterApplied) {
      setDisplayedPlanIds(Object.keys(results.plans).sort((a,b) => results.plans[a].true_cost - results.plans[b].true_cost))
      setIsFilterApplied(true)
      sessionStorage.setItem('filterApplied', JSON.stringify(true));
    }
  }, [isRecommendedPlans])

  // build list for plans to compare and limit at 4 entries
  const ComparedPlans = JSON.parse(sessionStorage.getItem('plansToCompare'));
  const [plansToCompare, setPlansToCompare] = useState(ComparedPlans ?? [])
  useEffect(() => {
    if (plansToCompare.length > 4) {
      setPlansToCompare( (oldPlans) => oldPlans.slice(0,4))
    }
          sessionStorage.setItem('plansToCompare', JSON.stringify(plansToCompare));
  }, [plansToCompare])

  // figure out basic info about results
  const sample_plan = results.plans[ results.recommended_plan_ids[0] ]
  const isProviders = sample_plan.provider_coverage.length > 0
  const isDrugs = sample_plan.drug_coverage.length > 0

  function optionToggleClass(option, state, custom = '') {
    return classNames('cursor-pointer px-5 py-4 ' + custom, {
      'border border-slate-600': state !== option,
      'bg-lumos-blue text-white': state === option
    })
  }

  let lv1 = 'summary'
  if (currentRoute !== 'summary') {
    lv1 = 'compare'
  }

  function optionButtonClass(option, state, custom = '') {
    return classNames('cursor-pointer cursor-pointer rounded-3xl px-4 py-2 ' + custom, {
      'text-lumos-blue': state !== option,
      'bg-lumos-blue text-white': state === option
    })
  }

  return(
    
    <div className="step flex flex-col border-black items-center">

      <div className="plans-switch info mt-5 text-center">
        <div className="switch flex grid grid-cols-2 mx-10 application-subtitle-text">
          <button 
            onClick={() => {
              setIsRecommendedPlans(true)
              sessionStorage.setItem('isRecommendedLastPage', JSON.stringify(true));
              if (!isRecommendedPlans) {
                navigate('summary')
              }
            }
            } 
            className={optionToggleClass(true, isRecommendedPlans, 'rounded-l')}>
            Recommended Plans ({results.recommended_plan_ids.length})
          </button>
          <button 
            onClick={() => {
              setIsRecommendedPlans(false)
              sessionStorage.setItem('isRecommendedLastPage', JSON.stringify(false));
              if (isRecommendedPlans){
                navigate('summary')
              }
            }
          } 
            className={optionToggleClass(false, isRecommendedPlans, 'rounded-r')}
          >
            See All Plans ({Object.keys(results.plans).length})
          </button>
        </div>
      </div>

      <div className='w-full'>
        <div className='flex text-lg mt-12 items-center'>
          <button 
            className={optionButtonClass('summary', lv1, 'mr-10')}
            onClick={() => navigate('summary')}
          >
            Summary
          </button>
          
          <button 
            className={`${
              optionButtonClass('compare', lv1, '')} disabled:cursor-not-allowed ${
              plansToCompare.length < 1 ? 'text-gray-500' : ''}`}
            onClick={() => navigate('cost')}
            disabled={plansToCompare.length < 1}
          >
            Compare ({plansToCompare.length})
          </button>

          {/* prompt to select plans if empty */}
          { plansToCompare.length == 0 &&
            <div className='ml-4'>
              <p className='ml-2 italic text-sm text-slate-500'>select plans to compare</p>
            </div>
          }      

          {!isRecommendedPlans && lv1 == 'summary' &&
            <div className="flex flex-col items-end font-semibold ml-auto">
              <button 
                onClick={() => setIsExpandFilters(!isExpandFilters)} 
                className={classNames('flex flex-row px-3 py-2 border border-lumos-blue rounded-lg  text-lumos-blue', {'bg-sky-50':isExpandFilters})}>
                <a className="mr-2">Filter</a>
                <img className="mt-1" src={filter} alt="filter" width="18"/>
              </button>
            </div>
          }
        </div>

        <hr className='border-sky-900 mt-4'/>

        {!isRecommendedPlans && lv1 == 'summary' && (
          <div>
            {(isFilterApplied) && (
              <PlanFilter
                displayedPlanIds={displayedPlanIds}
                setDisplayedPlanIds={setDisplayedPlanIds}
                recommendedPlanIds={results.recommended_plan_ids}
                isFilterApplied={isFilterApplied}
                setIsFilterApplied={setIsFilterApplied}
                isExpandFilters={isExpandFilters}
              />
            )}
          </div>
        )}

        {lv1 == 'compare' &&
          <>
            <div className='flex mt-3'>
              <button 
                className={`text-lumos-blue text-lg ml-4 ${currentRoute === 'cost' && 'underline font-bold'}`}
                onClick={() => navigate('cost')}
              >
                Cost
              </button>
              { (isProviders||isDrugs) &&
                <button 
                  className={`text-lumos-blue text-lg ml-10 ${currentRoute === 'coverage' && 'underline font-bold'}`}
                  onClick={() => navigate('coverage')}
                >
                  Coverage
                </button>
              }
            </div>
            <hr className='mt-2'></hr>
          </>
        }
      </div>

      { plansToCompare.length > 0 &&
        <div className='flex justify-left w-full mt-2'>
          {plansToCompare.map( planId =>
            <PlanPill
              planId = {planId}
              deselectPlan= {() => {setPlansToCompare( (oldPlans) => oldPlans.filter( id => id !== planId) )}}
            />
          )}
        </div>
      }
    
      
      <div className='max-w-6xl'>
        <Outlet context={[{isRecommendedPlans, plansToCompare, setPlansToCompare, isExpandFilters, displayedPlanIds}]}/>
      </div>

    </div>

  )

}

function PlanPill(props){
  const {planId, deselectPlan} = props
  const navigate = useNavigate()
  const results = useContext(ResultsContext)
  const plan = results.plans[planId]
  
  return (
    <div className = 'flex items-center border rounded-full p-2 h-12 mr-2'>
      <div className='flex hover:cursor-pointer'
      onClick={() => navigate(`/results/view/${plan.id}`)}>
        <div className='w-10'>
          <img src={getLogo(plan.issuer)} alt={plan.issuer}/>
        </div>
        <p className="ml-1 plan-stub-plan-name text-xs text-lumos-blue text-ellipsis line-clamp-2 w-40">{plan.plan_name}</p>
      </div>
      <div 
        className='text-slate-700 p-1 hover:cursor-pointer'
        onClick={deselectPlan}  
      >
        <i class="fa-solid fa-times"></i>
      </div>
    </div>
  )
}

function PlanFilter(props){

  const {displayedPlanIds, setDisplayedPlanIds, recommendedPlanIds, isFilterApplied, setIsFilterApplied, isExpandFilters} = props

  const results = useContext(ResultsContext)

  // find out if app had any preferred providers or drugs
  const samplePlan = results.plans[ recommendedPlanIds[0] ]
  const isProviders = samplePlan.provider_coverage.length > 0
  const isDrugs = samplePlan.drug_coverage.length > 0

  const storedFilterDetails = JSON.parse(sessionStorage.getItem('planFilterDetails'));

  const [IsNoReferralRequiredFlag, setIsNoReferralRequiredFlag] = useState(storedFilterDetails ? storedFilterDetails.IsNoReferralRequiredFlag : false);
  const [IsHSAEligibleFlag, setIsHSAEligibleFlag] = useState(storedFilterDetails ? storedFilterDetails.IsHSAEligibleFlag : false);
  const [IsStandardizedPlanFlag, setIsStandardizedPlanFlag] = useState(storedFilterDetails ? storedFilterDetails.IsStandardizedPlanFlag : false);

  // instantiate dictionary with carrier toggle
  const allCarriers = _.uniq( Object.values(results.plans).map(p => p.issuer)).sort()
  const [carriersToggle, setCarriersToggle] = useState(storedFilterDetails ? storedFilterDetails.carriersToggle : allCarriers.reduce((o, key) => ({ ...o, [key]: false}), {}))

  // instantiate dictionary with metal level
  const allLevels = _.uniq( Object.values(results.plans).map(p => p.metal_level)).sort()
  const [levelsToggle, setLevelsToggle] = useState(storedFilterDetails ? storedFilterDetails.levelsToggle : allLevels.reduce((o, key) => ({ ...o, [key]: false}), {}))

  // instantiate dictionary with provider toggle
  // better to use npi??
  const allProviders = isProviders ? Object.values(samplePlan.provider_coverage).map( val => val.name) : []
  const [providersToggle, setProvidersToggle] = useState(storedFilterDetails ? storedFilterDetails.providersToggle : allProviders.reduce((o, key) => ({ ...o, [key]: false}), {}))

  // instantiate dictionary with drug toggle
  // better to use rxcui??
  const allDrugs = isDrugs ? Object.values(samplePlan.drug_coverage).map( val => val.name) : []
  const [drugsToggle, setDrugsToggle] = useState(storedFilterDetails ? storedFilterDetails.drugsToggle : allDrugs.reduce((o, key) => ({ ...o, [key]: false}), {}))

  useEffect(() => {
    const filterDetailsToStore = {
      IsNoReferralRequiredFlag,
      IsHSAEligibleFlag,
      IsStandardizedPlanFlag,
      carriersToggle,
      levelsToggle,
      providersToggle,
      drugsToggle,
    };
    sessionStorage.setItem('planFilterDetails', JSON.stringify(filterDetailsToStore));
  }, [
    IsNoReferralRequiredFlag,
    IsHSAEligibleFlag,
    IsStandardizedPlanFlag,
    carriersToggle,
    levelsToggle,
    providersToggle,
    drugsToggle,
  ]);

  // apply filters and sort whenever a selection is made
  useEffect( () => {
    
    let newPlanIds = Object.keys(results.plans)

    const areAnyIssuersSelected = Object.values(carriersToggle).some((selected) => selected);
    if (areAnyIssuersSelected) {
      newPlanIds = newPlanIds.filter((id) => {
        const plan = results.plans[id];
        return Object.keys(carriersToggle).some((issuer) => carriersToggle[issuer] && plan.issuer === issuer);
      });
    }

    if(IsNoReferralRequiredFlag) {
      newPlanIds = newPlanIds.filter(
        (id) => {const plan = results.plans[id];
        return !plan.specialist_referral_required
      });
    }

    if(IsHSAEligibleFlag) {
      newPlanIds = newPlanIds.filter(
        (id) => {const plan = results.plans[id];
        return plan.hsa_eligible
      });
    }

    if(IsStandardizedPlanFlag) {
      newPlanIds = newPlanIds.filter(
        (id) => {const plan = results.plans[id];
        return plan.is_standardized_plan
      });
    }

    // filter for providers
    if (isProviders) { 
        // for each provider toggled on, for each plan, keep plan only if covered
        const selectedProviders = Object.keys(providersToggle).filter(key => providersToggle[key])
        for (const provider_name of selectedProviders){
            newPlanIds = newPlanIds.filter(id => {
                const item = results.plans[id].provider_coverage.find( x => x.name == provider_name)
                return item.is_covered
                }
            )
        }     
    }

    // filter for drugs
    if (isDrugs) { 
        for (const drug_name of Object.keys(drugsToggle).filter(key => drugsToggle[key])){
            newPlanIds = newPlanIds.filter(id => {
                const item = results.plans[id].drug_coverage.find( x => x.name == drug_name)
                return item.is_covered
                }
            )
        }     
    }

    //filter for plan level
    const areAnyLevelsSelected = Object.values(levelsToggle).some((selected) => selected);
    if (areAnyLevelsSelected) {
      newPlanIds = newPlanIds.filter((id) => {
        const plan = results.plans[id];
        return Object.keys(levelsToggle).some((level) => levelsToggle[level] && plan.metal_level === level);
      });
    }     

    setDisplayedPlanIds(newPlanIds)
    setDisplayedPlanIds(old => [...old].sort((a,b) => results.plans[a].true_cost - results.plans[b].true_cost) )
  }    
    , [carriersToggle, levelsToggle, providersToggle, drugsToggle, IsNoReferralRequiredFlag, IsHSAEligibleFlag, IsStandardizedPlanFlag]
  )

  return (
      <div className='bg-sky-50'>
      {isExpandFilters && 
        <div className="flex flex-row pl-10 py-3">
          <div className="carriers">
            <h2 className='text-md text-bold mt-2 font-semibold'>Carriers</h2>
            {Object.keys(carriersToggle).map( carrier => 
              <FilterItem
                key = {carrier}
                value = {carriersToggle[carrier]}
                label = {carrier}
                addSelection = { () => setCarriersToggle(
                  old => ({...old, [carrier]:true})
                  )}
                removeSelection = { () => setCarriersToggle(
                  old => ({...old, [carrier]:false})
                  )}
              />
            )}
          </div>
          <div className="flex flex-col">
          {isProviders &&
            <div className="providers pl-10">
              <h2 className='text-md text-bold mt-2 font-semibold'>Providers</h2>
              {Object.keys(providersToggle).map( provider => 
                <FilterItem
                  key = {provider}
                  value = {providersToggle[provider]}
                  label = {provider}
                  addSelection = { () => setProvidersToggle(
                    old => ({...old, [provider]:true})
                    )}
                  removeSelection = { () => setProvidersToggle(
                    old => ({...old, [provider]:false})
                    )}
                />
              )}
            </div>
          }
          {isDrugs &&
            <div className="drugs pl-10">
              <h2 className='text-md text-bold mt-2 font-semibold'>Drugs</h2>
              {Object.keys(drugsToggle).map( drug => 
                <FilterItem
                  key = {drug}
                  value = {drugsToggle[drug]}
                  label = {drug}
                  addSelection = { () => setDrugsToggle(
                    old => ({...old, [drug]:true})
                    )}
                  removeSelection = { () => setDrugsToggle(
                    old => ({...old, [drug]:false})
                    )}
                />
              )}
            </div>
          }
          </div>
          <div className="features pl-10">
            <h2 className='text-md text-bold mt-2 font-semibold'>Plan Features</h2>
            <div className="flex flex-col">
            {/* <label htmlFor="featureCheckbox" className='input cursor-pointer flex items-center py-1'>
              <input
                id="featureCheckbox"
                type="checkbox"
                checked={IsNoReferralRequiredFlag}
                onChange={() => setIsNoReferralRequiredFlag(!IsNoReferralRequiredFlag)}
              />
              <h2 className="ml-2 w-full text-sm">No Referral Required</h2>
            </label> */}
            <label htmlFor="featureCheckbox2" className='input cursor-pointer flex items-center py-1'>
              <input
                id="featureCheckbox2"
                type="checkbox"
                checked={IsHSAEligibleFlag}
                onChange={() => setIsHSAEligibleFlag(!IsHSAEligibleFlag)}
              />
              <h2 className="ml-2 w-full text-sm">HSA Eligible</h2>
            </label>
            <label htmlFor="featureCheckbox3" className='input cursor-pointer flex items-center py-1'>
              <input
                id="featureCheckbox3"
                type="checkbox"
                checked={IsStandardizedPlanFlag}
                onChange={() => setIsStandardizedPlanFlag(!IsStandardizedPlanFlag)}
              />
              <h2 className="ml-2 w-full text-sm">Standardized Plan</h2>
              <div className="tooltip-container ml-2">
                <div className="tooltip">
                    <span className="household-info-icon"><img src={info} alt="Info"/></span>
                    <i className="icon ri-arrow-right-line" />
                  <span className="tooltip-drop tooltip-left">
                  Standardized plans require insurers to follow a certain required designed as specified by
                  Healthcare.gov for each plan level. This means that standardized plans for a given plan level
                  will have the same set of pre-defined cost-sharing features (e.g. deductibles, copays, and coinsurance). 
                  </span>
                </div>
            </div>
            </label>
            </div>
            <div className="levels">
            <h2 className='text-md text-bold mt-2 font-semibold'>Plan Level</h2>
            {Object.keys(levelsToggle).map( level => 
              <FilterItem
                key = {level}
                value = {levelsToggle[level]}
                label = {level}
                addSelection = { () => setLevelsToggle(
                  old => ({...old, [level]:true})
                  )}
                removeSelection = { () => setLevelsToggle(
                  old => ({...old, [level]:false})
                  )}
              />
            )}
          </div>
          </div>
        </div>
        }
      </div>
  )
}


function FilterItem(props) {

  const {label, addSelection, removeSelection} = props
  const [value, setValue] = useState(props.value)

  useEffect( () => {
      if (value) {
          addSelection()
      } else {
          removeSelection()
      }
  }
  , [value])

  return (
    <label
      htmlFor={label}
      className='input cursor-pointer flex items-center py-1'
    >
      <input
        id={label}
        type="checkbox"
        checked={value}
        name={label}
        onChange={(e) => {
          setValue((old) => !old)
        }}
      />
      <h2 className="ml-2 w-full text-sm">
        {label}
      </h2>
    </label>
  )
}