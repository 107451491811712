import React, {useState, useEffect} from 'react'
import Confetti from 'react-confetti';

// intended to hold place while results info is being fetched

export default function LoadingComplete(){

  return (
    <div>
      <h2 className="text-4xl text-lumos-blue text-center font-semibold mt-12">
        Congratulations! Your results are ready! 
      </h2>
        <Confetti numberOfPieces={250}/>
  </div>
  )
}
