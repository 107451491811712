import React, {useState, useEffect, useRef} from 'react'
import resultsLoadingGIF from '../../assets/images/results_loading2.gif'
import pullplansGIF from '../../assets/images/pull_plans.gif';
import pullplanscompleteGIF from '../../assets/images/pull_plans_complete.gif';
import trueCostGIF from '../../assets/images/true_cost_calculation.gif';
import calculatingPremiumGIF from '../../assets/images/net_premium.gif';
import calculatingPremiumCompleteGIF from '../../assets/images/net_premium_complete.gif';
import outofpocket1 from '../../assets/images/out_of_pocket1.jpg';
import outofpocket2 from '../../assets/images/out_of_pocket2.jpg';
import outofpocket3 from '../../assets/images/out_of_pocket3.jpg';
import findpeopleGIF from '../../assets/images/find_people_combo2.gif';
import simulationGIF from '../../assets/images/simulation_combo2.gif';
import averageGIF from '../../assets/images/average_oop2.gif'

// intended to hold place while results info is being fetched

export default function LoadingPage(){
 
  const delays = [3000, 4620, 2800, 7500, 4920, 2800, 1000, 12000, 2000, 16000, 2000, 7000, 999999]
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {

    const intervalId = setInterval(() => {
        setCurrentIndex(currentIndex + 1);
    }, delays[currentIndex])
    
    return () => clearInterval(intervalId);

    }, [currentIndex])

  return (
    <div className="max-w-3xl">

  {(currentIndex===0) &&
        <div className="title info flex flex-col items-center">
          <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">
            Kicking off the process to generate recommendations...
          </h2>
          <img className="w-32 mt-12" src={resultsLoadingGIF} alt="loading animation"/>
        </div>
      }

  {(currentIndex===1) &&
            <div className="title info flex flex-col items-center">
              <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">
                Pulling all your eligible plans from healthcare.gov...
              </h2>
              <div className='mt-16 flex flex-col items-center'>
                <img width="80%" src={pullplansGIF} alt="pulling plans animation"/>
              </div>
            </div>
          }

  {(currentIndex===2) &&
            <div className="title info flex flex-col items-center">
              <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">
                Plans acquired. Preparing to calculate true cost...
              </h2>
              <div className='mt-16 flex flex-col items-center'>
                <img width="80%" src={pullplanscompleteGIF} alt="hourglass animation"/>
              </div>
            </div>
          }

  {(currentIndex===3) &&
              <div className="title info flex flex-col items-center">
                <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">
                  Setting up the true cost calculation...
                </h2>
                <div className='mt-16 text-xl text-lumos-gray font-semibold'>
                <img src={trueCostGIF} alt="true cost calculation"/>
                </div>
              </div>
      }

  {(currentIndex===4) &&
              <div className="title info flex flex-col items-center">
                <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">
                  Calculating the net premium for all plans...
                </h2>
                <div className='mt-16 flex flex-col items-center'>
                <img width="80%" src={calculatingPremiumGIF} alt="net premium animation"/>
                </div>
              </div>
      }

  {(currentIndex===5) &&
              <div className="title info flex flex-col items-center">
                <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">
                  Net premium calculation complete.
                </h2>
                <div className='mt-16 flex flex-col items-center'>
                <img width="80%" src={calculatingPremiumCompleteGIF} alt="net premium calculation complete animation"/>
                </div>
              </div>
      }

  {(currentIndex===6) &&
        <div className="title info flex flex-col items-center">
          <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">
            Preparing to calculate out-of-pocket expenses...
          </h2>
          <div className='mt-12 text-2xl text-black font-semibold'>
            Step 1: Find people/families like you and get their real health usage
          </div>
        </div>
      }

  {(currentIndex===7) &&
          <div className="title info flex flex-col items-center">
            <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">
              Preparing to calculate out-of-pocket expenses...
            </h2>
            <div className='mt-12 text-2xl flex flex-col items-center text-black font-semibold'>
              Step 1: Find people/families like you and get their real health usage
              <img className="mt-6" width="80%" src={findpeopleGIF} alt="finding similar people animation"/>
            </div>
          </div>
      }

  {(currentIndex===8) &&
        <div className="title info flex flex-col items-center">
          <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">
            Preparing to calculate out-of-pocket expenses...
          </h2>
          <div className='mt-12 text-2xl text-black font-semibold'>
            Step 2: Simulate what you would pay under each plan if you had the same health usage as people like you
          </div>
        </div>
      }

  {(currentIndex===9) &&
        <div className="title info flex flex-col items-center">
          <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">
            Preparing to calculate out-of-pocket expenses...
          </h2>
          <div className='mt-12 text-2xl text-black font-semibold flex flex-col items-center'>
            Step 2: Simulate what you would pay under each plan if you had the same health usage as people like you
            <img className="mt-6" width="80%" src={simulationGIF} alt="finding similar people animation"/>
          </div>
        </div>
          }

  {(currentIndex===10) &&
          <div className="title info flex flex-col items-center">
            <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">
              Preparing to calculate out-of-pocket expenses...
            </h2>
            <div className='mt-12 text-2xl text-black font-semibold'>
              Step 3: Average estimates across people like you for each plan to get out-of-pocket expenses
            </div>
          </div>
    }

  {(currentIndex===11) &&
          <div className="title info flex flex-col items-center">
            <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">
              Preparing to calculate out-of-pocket expenses...
            </h2>
            <div className='mt-12 text-2xl text-black flex flex-col items-center font-semibold'>
              Step 3: Average estimates across people like you for each plan to get out-of-pocket expenses
              <img className="mt-6" width="80%" src={averageGIF} alt="finding similar people animation"/>
            </div>
          </div>
    }

  {(currentIndex===12) &&
        <div className="title info flex flex-col items-center mt-12">
          <img className="w-32" src={resultsLoadingGIF} alt="loading animation"/>
          <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-16">
            Completing final processing. Results will be ready shortly...
          </h2>
        </div>
              }

  </div>
  )
}
