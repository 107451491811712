import React, { useState, useContext, useRef, useEffect } from 'react'
import filter from '../../assets/icons/filter_lines.svg'
import { ResultsContext, ApplicationContext } from './Contexts'
import _ from 'lodash'
import classNames from 'classnames'


import PlanStub from './PlanStub'


export default function AllPlans(props){
  
  const [isFilterActive, setIsFilterActive] = useState(false)
  const results = useContext(ResultsContext)
  const [filteredPlanIds, setFilteredPlanIds] = useState(Object.keys(results.plans) )
  const samplePlan = results.plans[results.recommended_plan_ids[0]]
  const isProviders = samplePlan.provider_coverage.length > 0
  const isDrugs = samplePlan.drug_coverage.length > 0

  console.log(filteredPlanIds)

  const allCarriers = _.uniq( Object.values(results.plans).map(p => p.issuer)).sort()
  const [carriersToggle, setCarriersToggle] = useState(allCarriers.reduce((o, key) => ({ ...o, [key]: false}), {}))

//   filter will require only plans that cover to show if checked
  const allProviders = isProviders ? Object.values(samplePlan.provider_coverage).map( val => val.name) : []
  const [providersToggle, setProvidersToggle] = useState(allProviders.reduce((o, key) => ({ ...o, [key]: false}), {}))

// filter along drugs
  const allDrugs = isDrugs ? Object.values(samplePlan.drug_coverage).map( val => val.name) : []
  const [drugsToggle, setDrugsToggle] = useState(allDrugs.reduce((o, key) => ({ ...o, [key]: false}), {}))

  useEffect(() => {
        setFilteredPlanIds(old => [...old].sort((a,b) => results.plans[a].true_cost - results.plans[b].true_cost) )
    }
    , []
  )

  useEffect( () => {
    // filter for carriers
    const selectedCarriers = allCarriers.filter( c => carriersToggle[c])
    let newPlans = Object.keys(results.plans).filter( plan_id => selectedCarriers.includes(results.plans[plan_id].issuer))
    // filter for providers
    if (isProviders) { 
        for (const provider_name of Object.keys(providersToggle).filter(key => providersToggle[key])){
            newPlans = newPlans.filter(plan_id => {
                const item = results.plans[plan_id].provider_coverage.find( x => x.name == provider_name)
                return item.is_covered
                }
            )
        }     
    }
    // filter for drugs
    if (isDrugs) { 
        for (const drug_name of Object.keys(drugsToggle).filter(key => drugsToggle[key])){
            newPlans = newPlans.filter(plan_id => {
                const item = results.plans[plan_id].drug_coverage.find( x => x.name == drug_name)
                return item.is_covered
                }
            )
        }     
    }

    // sort by true cost
    newPlans.sort((a,b) => results.plans[a].true_cost - results.plans[b].true_cost)
    setFilteredPlanIds(newPlans)

  }, [carriersToggle, providersToggle, drugsToggle])

  

  const not_found_lkp = {
    'best w/ providers covered': 'covers all of your listed providers'
    , 'best no referral required': 'does not require a referral to see a specialist'
  }
  const not_found_txt = results.not_found.map( item => not_found_lkp[item])
    .join(' or ')

  return(
    <div className="step flex flex-col">

      <div className="mt-5">
        <div className="filter-menu border-b border-lumos-blue mt-2 w-full">
            <div className="flex flex-col items-end font-semibold mb-6">
              <button 
              onClick={() => setIsFilterActive(!isFilterActive)} 
              className={classNames('flex flex-row px-3 py-2 border border-lumos-blue rounded-lg text-lumos-blue', {'bg-sky-50':isFilterActive})}>
              <a className="mr-2">Filter</a>
              <img className="mt-1" src={filter} alt="filter" width="18"/>
              </button>
            </div>

            <div className='bg-sky-50'>
            {isFilterActive &&
              <>
              <div className="flex flex-row pl-10 py-3 border-t border-lumos-blue">
              <div className="carriers">
              <h2 className='text-md text-bold mt-2'>Carriers</h2>
            {Object.keys(carriersToggle).map( carrier => 
                <FilterItem
                key = {carrier}
                value = {carriersToggle[carrier]}
                label = {carrier}
                addSelection = { () => setCarriersToggle(
                    old => ({...old, [carrier]:false})
                    )}
                    removeSelection = { () => setCarriersToggle(
                        old => ({...old, [carrier]:true})
                        )}
                        />
                        )}
                </div>
            {isProviders &&
                <>
                <div className="providers pl-10">
                <h2 className='text-md text-bold mt-2'>Providers</h2>
                {Object.keys(providersToggle).map( provider => 
                    <FilterItem
                    key = {provider}
                    value = {providersToggle[provider]}
                    label = {provider}
                    addSelection = { () => setProvidersToggle(
                        old => ({...old, [provider]:true})
                        )}
                        removeSelection = { () => setProvidersToggle(
                            old => ({...old, [provider]:false})
                            )}
                            />
                            )}
                  </div>
                </>
            }
            {isDrugs &&
                <>
                <div className="drugs pl-10">
                <h2 className='text-md text-bold mt-2'>Drugs</h2>
                {Object.keys(drugsToggle).map( drug => 
                    <FilterItem
                    key = {drug}
                    value = {drugsToggle[drug]}
                    label = {drug}
                    addSelection = { () => setDrugsToggle(
                        old => ({...old, [drug]:true})
                        )}
                        removeSelection = { () => setDrugsToggle(
                            old => ({...old, [drug]:false})
                            )}
                            />
                            )}
                </div>
                </>
            }
            </div>
            </>
            }
            </div>
        </div>
      </div>

      <div className="details ml-5 mt-8 max-w-6xl">
        {/* <div className='text-lumos-blue text-center text-2xl font-medium'>
          Showing {filteredPlanIds.length} out of {Object.keys(results.plans).length} plans 
        </div> */}
        { (filteredPlanIds.length > 0) ?
          filteredPlanIds.map(plan_id => 
            <div className='mt-6'>
              <PlanStub 
                key={plan_id}
                plan={results.plans[plan_id]} 
                subsidy={results.subsidy} 
                isDrugs={isDrugs} 
                isProviders={isProviders} 
              />
              
            </div>
          ) : 
          <div className="no-match-text mt-10 max-w-6xl">
            <h1 className='text-4xl text-center'> No plans match your criteria.</h1>
          </div>
        }
      </div>
    </div>
  )
}

function FilterItem(props) {

    const {label, addSelection, removeSelection} = props
    const [value, setValue] = useState(props.value)

    useEffect( () => {
        if (value) {
            addSelection()
        } else {
            removeSelection()
        }
    }
    , [value])

    return (
        <label
      htmlFor={label}
      className='input cursor-pointer flex items-center py-1'
    >
      <input
        id={label}
        type="checkbox"
        checked={value}
        name={label}
        onChange={(e) => {
          setValue((old) => !old)
        }}
      />
      <h2 className="ml-2 w-full text-xs">
        {label}
      </h2>
    </label>
    )
}
