import React, { useContext, useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Select from 'react-select'
import { getLogo, optionClass } from "../../utils/utility"

import axios from 'axios'
import { getSpec } from "../../utils/apis"
import { ResultsContext, ApplicationContext } from "./Contexts"

import { getNetPremium, commaFormat, metalClass } from "../../utils/utility"

import CostComparison2 from './CostComparison2'

import { Chart as ChartJS, CategoryScale, PointElement, LinearScale, LineElement, Tooltip } from 'chart.js'

import doctorIcon from '../../assets/icons/doctor.png';
import drugsIcon from '../../assets/icons/drug.png';
import xrayIcon from '../../assets/icons/xray.png';
import hospitalIcon from '../../assets/icons/hospital.png';
import therapyIcon from '../../assets/icons/therapy.png';
import pregnancyIcon from '../../assets/icons/pregnancy.png';
  
ChartJS.register( CategoryScale, LinearScale, LineElement, PointElement, Tooltip )


const options = [
    { value: 1, label: 'Doctor visits', categories: ['PRIMARY_CARE_VISIT_TO_TREAT_AN_INJURY_OR_ILLNESS','SPECIALIST_VISIT'],
        simple_categories: ['Primary Care Visit', 'Specialist Visit']}
    , { value: 2, label: 'Prescription drugs', categories: ['GENERIC_DRUGS','PREFERRED_BRAND_DRUGS','NON_PREFERRED_BRAND_DRUGS','SPECIALTY_DRUGS'],
        simple_categories:['Generic Drugs','Preferred Brand Drugs','Non-Preferred Brand Drugs','Specialty Drugs']}
    , { value: 3, label: 'Labs and imaging', categories: ["LABORATORY_OUTPATIENT_AND_PROFESSIONAL_SERVICES","IMAGING_CT_PET_SCANS_MRIS","X_RAYS_AND_DIAGNOSTIC_IMAGING"],
        simple_categories:['Lab Work', 'Imaging (CT,PET,MRIS)', 'X-Rays'] }
    , { value: 4, label: 'Hospital and emergency', 
        categories:["URGENT_CARE_CENTERS_OR_FACILITIES","EMERGENCY_ROOM_SERVICES","EMERGENCY_TRANSPORTATION_AMBULANCE",
        "INPATIENT_HOSPITAL_SERVICES_EG_HOSPITAL_STAY","INPATIENT_PHYSICIAN_AND_SURGICAL_SERVICES","OUTPATIENT_FACILITY_FEE_EG_AMBULATORY_SURGERY_CENTER","OUTPATIENT_SURGERY_PHYSICIAN_SURGICAL_SERVICES"],
        simple_categories:['Urgent Care','Emergency Room', 'ER Ambulance','Inpatient Hospital (Stay)', 'Inpatient Hospital (Services)', 'Outpatient Facility', 'Outpatient Services']}
    , { value: 5, label: 'Mental health and substance abuse',categories: ["MENTAL_BEHAVIORAL_HEALTH_OUTPATIENT_SERVICES","MENTAL_BEHAVIORAL_HEALTH_INPATIENT_SERVICES","SUBSTANCE_ABUSE_DISORDER_OUTPATIENT_SERVICES","SUBSTANCE_ABUSE_DISORDER_INPATIENT_SERVICES"],
        simple_categories:['Mental Health (Outpatient)', 'Mental Health (In-patient)', 'Substance Abuse (Outpatient)', 'Substance Abuse (Services)']}
    , { value: 6, label: 'Pregnancy & Childbirth',categories: ["PRENATAL_AND_POSTNATAL_CARE","DELIVERY_AND_ALL_INPATIENT_SERVICES_FOR_MATERNITY_CARE"],
    simple_categories:['Pre/post natal care', 'Labor, delivery, and hospital']}
  ]

  const category_icon = {
    1: doctorIcon,
    2: drugsIcon,
    3: xrayIcon,
    4: hospitalIcon,
    5: therapyIcon,
    6: pregnancyIcon
  }

  const category_explain = {
    1: 'This applies to doctor visits when you are dealing with a health condition or symptom. Preventive care visits, such as annual check-ups, are free. Refer to plan documents for complete details',
    2: 'Prescription drug costs are based on how the drug is classified. Drug classifications are determined by the insurer. Review the plan drug formulary link in Plan Documents. Refer to plan documents for complete details',
    3: 'These are tests and proceudres prescribed by doctor to diagnose or check the status of a condition. Refer to plan documents for complete details',
    4: 'These are specialized services you may use in an emergency or for specialized treatment. These services tend to be the most expensive so you should be certain to check that you use an in-network provider. You can do so by confirming with both the provider and insurer that the provider is in-network for your plan. Refer to plan documents for complete details',
    5: 'Plans cover behavioral, mental health inpatient services, and substance abuse treatments. Refer to plan documents for complete details.',
    6: 'Plans cover services before and after your child is born. Refer to plan documents for complete details'
  }

  const category_map = {
    'PRIMARY_CARE_VISIT_TO_TREAT_AN_INJURY_OR_ILLNESS': 'Primary Care Visit',
    'SPECIALIST_VISIT': 'Specialist Visit',
    'GENERIC_DRUGS': 'Generic Drugs',
    'PREFERRED_BRAND_DRUGS':'Preferred Brand Drugs',
    'NON_PREFERRED_BRAND_DRUGS':'Non-Preferred Brand Drugs',
    'SPECIALTY_DRUGS':'Specialty Drugs',
    'LABORATORY_OUTPATIENT_AND_PROFESSIONAL_SERVICES':'Lab Work',
    'IMAGING_CT_PET_SCANS_MRIS':'Imaging (CT,PET,MRIS)',
    'X_RAYS_AND_DIAGNOSTIC_IMAGING':'X-Rays',
    'URGENT_CARE_CENTERS_OR_FACILITIES':'Urgent Care',
    'EMERGENCY_ROOM_SERVICES':'Emergency Room',
    'EMERGENCY_TRANSPORTATION_AMBULANCE':'ER Ambulance',
    'INPATIENT_HOSPITAL_SERVICES_EG_HOSPITAL_STAY':'Inpatient Hospital (Stay)',
    'INPATIENT_PHYSICIAN_AND_SURGICAL_SERVICES':'Inpatient Hospital (Physician)',
    'OUTPATIENT_FACILITY_FEE_EG_AMBULATORY_SURGERY_CENTER':'Outpatient Facility',
    'OUTPATIENT_SURGERY_PHYSICIAN_SURGICAL_SERVICES':'Outpatient Services',
    'MENTAL_BEHAVIORAL_HEALTH_OUTPATIENT_SERVICES':'Mental Health (Outpatient)',
    'MENTAL_BEHAVIORAL_HEALTH_INPATIENT_SERVICES':'Mental Health (In-patient)',
    'SUBSTANCE_ABUSE_DISORDER_OUTPATIENT_SERVICES':'Substance Abuse (Outpatient)',
    'SUBSTANCE_ABUSE_DISORDER_INPATIENT_SERVICES':'Substance Abuse (Services)',
    'PRENATAL_AND_POSTNATAL_CARE':'Pre/post natal care',
    'DELIVERY_AND_ALL_INPATIENT_SERVICES_FOR_MATERNITY_CARE':'Labor, delivery, and hospital'
  }


export default function ViewPlan() {

    const results = useContext(ResultsContext)
    const application = useContext(ApplicationContext)
    const [section, setSection] = useState('details')
    const {id} = useParams()

    //check if spec is already in session storage
    const savedPlanSpec = JSON.parse(sessionStorage.getItem(id));
    const [planSpec, setPlanSpec] = useState(savedPlanSpec ?? null)

    const [networkOptions, setNetworkOptions] = useState(null)
    const [planParameter, setPlanParameter] = useState(null)
    const [networkParameter, setNetworkParameter] = useState(null)
    const plan = results.plans[id]

    const navigate = useNavigate()

    useEffect(() => {
        if(!planSpec) {
            //get planSpec via API and store it in session storage
            getSpec(application, id).then( res => {
                setPlanSpec(res)
                sessionStorage.setItem(id, JSON.stringify(res))
            })
        }
          }, [id])

    function getNetworkSelection(category_value) {
        const types = options[category_value - 1].categories;
        // Use a Set to keep track of unique network types
        const uniqueNetworkTypes = new Map();
        // Loop over the array elements
        planSpec[0]['benefits'].forEach(element => {
            // Check if the element has a property called "type"
            if (element.type !== undefined && types.includes(element.type)) {
                // Check if "cost_sharings" is an array and has elements
                if (Array.isArray(element.cost_sharings) && element.cost_sharings.length > 0) {
                    // Loop over the "cost_sharings" array
                    element.cost_sharings.forEach(costSharing => {
                        // Access the "network_tier" property from the nested structure
                        const networkType = costSharing.network_tier;
                        
                        // Check if networkType is defined and not already added
                        if (networkType !== undefined && !uniqueNetworkTypes.has(networkType)) {
                            uniqueNetworkTypes.set(networkType, uniqueNetworkTypes.size + 1);
                        }
                    });
                }
            }
        });
    
        // Convert the Set back to an array if needed
        const networkTypesArray = Array.from(uniqueNetworkTypes, ([label, value]) => ({ value, label }));
        setNetworkOptions(networkTypesArray)
        return;
    }

    return (
        <div className="plan w-full">

            {/* top navigation and request to enroll*/}
            <div className="header-actions flex justify-between items-center">
                <div className="logo flex justify-between items-start">
                <button 
                    onClick={() => {
                    navigate('/results/shop/summary')
                    }}
                    className="text-2xl text-lumos-blue px-8"
                >
                    &#x2190; Back
                </button>
                </div>
            </div>
                    
            {/* header title for plan */}
            <div className="mt-20 flex justify-between items-start px-8">
                <img className="w-48" src={getLogo(plan.issuer)} alt={plan.issuer}/>
                <div className="enroll-button">
                    { results.stateSupport === "supported" && 
                    <button 
                        onClick={() => {
                        // window.scrollTo(0,0)
                        // setStage('enroll')
                            navigate(`/results/enroll/${id}`)
                        }} 
                        className="px-8 py-3 bg-lumos-green text-white text-lg rounded-lg"
                    >
                        Enroll in this Plan
                    </button>
                    }
                    { results.stateSupport === "partial" && 
                    <div className="tooltip-container ml-8">
                        <div className="tooltip">
                            <button 
                            className="px-8 py-2 bg-gray-400 text-white rounded-lg cursor-not-allowed " 
                            disabled
                            >
                            Enroll
                            </button>
                        <span className="tooltip-drop tooltip-bottom">
                            We are not yet able to process enrollments in your state. Until then, you enroll directly on www.healthcare.gov.
                        </span>
                        </div>
                    </div>
                    }
                </div>
                </div>
                <div>
                <h2 className="text-3xl text-lumos-blue mt-8 px-8">{plan.plan_name}</h2>
            </div>
            
            <div className="bg-white">
                <div className="mb-8 w-full">
                    {/* display according to section toggle */}
                    {<PlanDetails plan={plan} subsidy={results.subsidy} /> }
                </div>
{/*                 <div className="px-16">
                    <h2 className="text-lumos-blue text-3xl font-semibold mt-10">What Will I Pay For Health Services?</h2>
                    <hr className="border border-lumos-blue w-full mt-2"/>
                    <div className="flex flex-col">
                        <div className="flex flex-row">
                            <div className="category-select-button bg-white mt-8 mr-20">
                            <div>
                                <p className="mb-2 font-semibold text-lumos-blue text-lg">Service Type</p>
                                <Select
                                    options={options}
                                    className="font-medium text-lg"
                                    onChange={(option) => {
                                        setPlanParameter(option.value)
                                        getNetworkSelection(option.value)
                                    }}
                                    styles={
                                        {valueContainer: (provided, state) => ({
                                        ...provided,
                                        height: '50px',
                                        width: '250px'
                                        })}
                                    }
                                />
                            </div>
                            </div>
                            <div className="network-select-button mt-8">
                                <div>
                                    <p className="mb-2 font-semibold text-lumos-blue text-lg">Network Tier</p>
                                    {planParameter &&
                                    <Select
                                        options={networkOptions}
                                        className="font-medium text-lg"
                                        onChange={(option) => {
                                            setNetworkParameter(option.value)
                                        }}
                                        styles={
                                            {valueContainer: (provided, state) => ({
                                            ...provided,
                                            height: '50px',
                                            width: '250px'
                                            })}
                                        }
                                    />
                                    }
                                    {!planParameter &&
                                        <Select
                                        options={networkOptions}
                                        isDisabled={true}
                                        styles={
                                            {valueContainer: (provided, state) => ({
                                                ...provided,
                                                height: '50px',
                                                width: '250px'
                                            })}
                                        }
                                    />
                                    }
                                </div>
                            </div>
                        </div>
                            {(planParameter) && (networkParameter) &&
                                    <div className="flex flex-row mt-6">
                                        <div>
                                            <img className="plan-param-img" src={category_icon[planParameter]}></img>
                                        </div>
                                        <div className="mt-4 ml-4 italic text-lg text-lumos-blue">
                                            {category_explain[planParameter]}
                                        </div>
                                    </div>
                                }
                            <div className="mt-6 text-black">
                                {((planParameter) && (networkParameter)) &&
                                    <PlanParameters 
                                        planSpec={planSpec}
                                        options={options}
                                        categoryValue={planParameter}
                                        networkOptions={networkOptions}
                                        networkParameter={networkParameter}
                                    />
                                }
                            </div>
                    </div>
                </div> */}
                <div className="px-16">
                    <h2 className="text-lumos-blue text-3xl font-semibold mt-10">True Cost</h2>
                    <hr className="border border-lumos-blue w-full mt-2"/>
                    {<CostComparison2 planIds={[plan.id]} /> }
                </div>
            </div>
        </div>
    )
}

function PlanParameters(props) {
    const {planSpec, options, categoryValue, networkOptions, networkParameter} = props
    const fullCategories = options[categoryValue-1].categories
    const simpleCategories = options[categoryValue-1].simple_categories
    const networkType = networkOptions[networkParameter-1].label

    const tableData = [];
    for (let i = 0; i < simpleCategories.length; i++) {
        tableData.push({ column1: "", column2: 'Unavailable. Refer To Plan Docs', column3: 'Unavailable. Refer To Plan Docs' });
    }

    const stringsToRemove = ["/", "Copay","after deductible","with deductible", "Coinsurance after deductible", "Coinsurance", "After Deductible"];

    let i = 0;
    planSpec[0]['benefits'].forEach(element => {
        if (element.type !== undefined && fullCategories.includes(element.type)) {
            element.cost_sharings.forEach(sharing => {
                tableData[i].column1 = category_map[element.type]
                if (sharing.network_tier !== undefined && (networkType===sharing.network_tier)) {
                    if(sharing.benefit_before_deductible === 'Not BBD') {
                        if((sharing.coinsurance_options === 'Not Applicable') && (sharing.copay_options != 'Not Applicable')) {
                            tableData[i].column2='100%'
                            tableData[i].column3=sharing.display_string
                        } else if ((sharing.coinsurance_options !== 'Not Applicable') && (sharing.copay_options === 'Not Applicable')) {
                            tableData[i].column2='100%'
                            tableData[i].column3=sharing.display_string
                        } else if((sharing.coinsurance_options !== 'Not Applicable') && (sharing.copay_options !== 'Not Applicable')) {
                            tableData[i].column2='100%'
                            tableData[i].column3=sharing.display_string
                        }
                        if(tableData[i].column3 === 'Not Covered') {
                            tableData[i].column2='Not Covered'
                        }
                    } else if (sharing.benefit_before_deductible === 'BBD') {
                        if((sharing.coinsurance_options === 'Not Applicable') && (sharing.copay_options !== 'Not Applicable')) {
                            tableData[i].column2=sharing.display_string
                            tableData[i].column3=sharing.display_string
                        } else if ((sharing.coinsurance_options !== 'Not Applicable') && (sharing.copay_options === 'Not Applicable')) {
                            tableData[i].column2=sharing.display_string
                            tableData[i].column3=sharing.display_string
                        } else if((sharing.coinsurance_options !== 'Not Applicable') && (sharing.copay_options !== 'Not Applicable')) {
                            tableData[i].column2=sharing.display_string
                            tableData[i].column3=sharing.display_string
                        }
                    }
                    stringsToRemove.forEach(str => {
                        if(str==="/") {
                            tableData[i].column2 = tableData[i].column2.replace(str, "with ").trim();
                            tableData[i].column3 = tableData[i].column3.replace(str, "with ").trim();
                        } else {
                            tableData[i].column2 = tableData[i].column2.replace(str, "").trim();
                            tableData[i].column3 = tableData[i].column3.replace(str, "").trim();   
                        }

                        });
                }
            });
            i+=1;
        }
    });

    const sortedtableData = tableData.sort((a, b) => a.column1.localeCompare(b.column1));

    return (
        <div>
        <table style={{ borderCollapse: 'collapse', width: '100%', fontSize: '1.1rem', lineHeight: '1.75rem'}}>
      <thead>
        <tr style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', background: '#F2FCFF' }}>
          <th style={{ padding: '10px' }}></th>
          <th style={{ padding: '10px', fontWeight: '600' }}>Before Deductible Is Met</th>
          <th style={{ padding: '10px', fontWeight: '600' }}>After Deductible Is Met</th>
        </tr>
      </thead>
      <tbody>
        {sortedtableData.map((row, index) => (
          <tr key={index} style={{ border: '1px solid #ddd', padding: '8px' }}>
            <td style={{ padding: '10px', fontSize: '1.05rem', lineHeight: '1.25rem' }}>{row.column1}</td>
            <td style={{ padding: '10px', fontSize: '1.05rem', lineHeight: '1.25rem' }}>{row.column2}</td>
            <td style={{ padding: '10px', fontSize: '1.05rem', lineHeight: '1.25rem' }}>{row.column3}</td>
          </tr>
        ))}
      </tbody>
        </table>
        </div>
    )
}

function PlanDetails(props) {
    const {plan, subsidy} = props
    const application = useContext(ApplicationContext)

    return( 
    
    <div className="plan-summary px-16">
        <h2 className="text-lumos-blue text-3xl font-semibold mt-10">Plan Summary</h2>
        <hr className="border border-lumos-blue w-full mt-2"/>
        <div className="table grid grid-cols-2 items-center">

            <div className="mt-4 font-semibold">True Cost (monthly)</div>
            <div className="flex mt-4 justify-between items-center">
                <div className="value"> ${commaFormat(plan.true_cost/12)}</div>
            </div>
            
            <hr className="col-span-2 mt-4 border-0.5 border-black"/>
            <div className="mt-4 font-semibold">Monthly Premium</div>
            <div className="flex mt-4 justify-between items-center">
                <div className="value">
                    ${getNetPremium(plan.premium, subsidy)}/mo 
                    <span className="text-red-600 ml-4 text-sm">(${plan.premium}/mo without subsidy)</span>
                </div>
            </div>
            
            <hr className="col-span-2 mt-4 border-0.5 border-black"/>
            <div className="mt-4 font-semibold">Network Type</div>
            <div className="flex mt-4 justify-between items-center">
                <div className="value">
                    <span> {plan.type} </span>
                </div>
            </div>
            
            {/* <hr className="col-span-2 mt-4 border-0.5 border-black"/>
            <div className="mt-4 font-semibold">Referral Required To See A Specialist?</div>
            <div className="flex mt-4 justify-between items-center">
                <div className="value"> {plan.is_referral ? 'Yes' : 'No'} </div>
            </div> */}

            <hr className="col-span-2 mt-4 border-0.5 border-black"/>
            <div className="mt-4 font-semibold">HSA Eligible?</div>
            <div className="flex mt-4 justify-between items-center">
                <div className="value">
                    <span> {plan.hsa_eligible ? "Yes" : "No"} </span>
                </div>
            </div>

            {/* only show if preferred providers/drugs is non-empty */}
            
            { plan.provider_coverage && plan.provider_coverage.length > 0 &&
            <>
                <hr className="col-span-2 mt-4 border-0.5 border-black"/>
                <DetailCoverageExpandable coverage={plan.provider_coverage} title="Providers"/>
            </>
            }
            { plan.drug_coverage && plan.drug_coverage.length > 0 &&
            <>
                <hr className="col-span-2 mt-4 border-0.5 border-black"/>
                <DetailCoverageExpandable coverage={plan.drug_coverage} title="Drugs"/>
            </>
                
            }

            <hr className="col-span-2 mt-4 border-0.5 border-black"/>
            <div className="mt-4 font-semibold">
                Plan Level
            </div>
            <div className="flex mt-4 items-center">
                <div className={metalClass(plan.metal_level)}>
                    <span className="text-xs mr-3">&#11044;</span> 
                    <span className="text-lg">{plan.metal_level}</span>
                </div>
            </div>

            {/* new */}
            {/* individual deductible */}
            <hr className="col-span-2 mt-4 border-0.5 border-black"/>
            <div className="mt-4 font-semibold">
                { Object.keys(plan.deductible).length > 1 ?
                    "Individual Deductible"
                    :"Deductible"
                }
            </div>
            {Object.keys(plan.deductible.individual).map( key => 
                <>
                <div className="flex items-center col-start-2">
                    <p className="w-24">${commaFormat(plan.deductible.individual[key])}</p>
                    <p className="ml-2">{key}</p>
                </div>
                </>
            )}
            {/* family deductible if it applies */}
            { Object.keys(plan.deductible).length > 1 &&
            <>
                <div className="mt-4 font-semibold"> Family Deductible</div>
                {Object.keys(plan.deductible.family).map( key => 
                    <>
                    <div className="flex items-center col-start-2">
                        <p className="w-24">${commaFormat(plan.deductible.family[key])}</p>
                        <p className="ml-2">{key}</p>
                    </div>
                    </>
                )}
            </>
            }

            <hr className="col-span-2 mt-4 border-0.5 border-black"/>
            <div className="mt-4 font-semibold">Out-of-Pocket Maximum</div>

            {Object.keys(plan.oop_max).length === 1 ?
            <>
                <div className="mt-4">
                    ${commaFormat(plan.oop_max.individual)}
                </div>
            </>   
            : <>
                <div className="flex col-start-2 mt-4">
                    <p className="w-24">${commaFormat(plan.oop_max.individual)}</p>
                    <p className="ml-2">Individual</p>
                </div>
                <div className="flex col-start-2 mt-4">
                    <p className="w-24">${commaFormat(plan.oop_max.family)}</p>
                    <p className="ml-2">Family</p>
                </div>
            </> 
        }
            
            <hr className="col-span-2 mt-4 border-0.5 border-black"/>
            <div className="mt-4 font-semibold">Plan Documents</div>
            <div className="flex mt-4 justify-between items-center">
                <div className="value">
                {plan.links && 
                    plan.links.map( link => 
                    <div className="link">
                        <a target="_blank" className="text-blue-500 ml-2" href={link.url}>
                        &#128279;
                        <span className="ml-2">{link.name}</span>                   
                        </a>
                    </div>
                    )
                }
                </div>
            </div>

            <hr className="col-span-2 mt-4 border-0.5 border-black"/>
        </div>
    </div>
    
    )
}

function DetailCoverageExpandable(props){

    const {coverage, title} = props

    const [expanded, setExpanded] = useState(false)

    return (
        <div className="col-span-2 w-full grid grid-cols-2 mt-4 hover:cursor-pointer"
            onClick={()=>setExpanded(old=>!old)}
        >
            
            {/* title row */}
            <p className= "font-bold">{title}:</p>
            <div className="flex items-center">
                <p>{coverage.filter(item => item.is_covered).length} of {coverage.length} covered &nbsp;&nbsp;</p>
                {expanded ? 
                    <span className='text-xs'>&#9650;</span>
                    : <span className='text-xs'>&#9660;</span>
                }
            </div>

            {/* expanded rows */}
            {expanded && 
            coverage.map((item, index) => 
                <div key={title+"-"+index} className="col-span-2 grid grid-cols-2 p-2 margin margin-color-white bg-gray-100 ">
                    <p>{item.name}</p> 
                    {item.is_covered ?
                        <span className="text-green-600">Covered</span>
                        : <span className="text-red-600">Not covered</span>
                    }
                </div>
            )}

        </div>
    )
}

