import React, { useEffect, useState, useRef } from 'react'
import { useOutletContext, useNavigate } from "react-router-dom"

import axios from 'axios'

import { isEmail } from '../../utils/utility.js'



export default function Submit(props){

  const {application, setApplication} = useOutletContext()
  const [errors, setErrors] = useState("")
  const [email, setEmail] = useState( application.email ? application.email : "")
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()


  // autofocus text input
  const textInput = useRef(null)
  useEffect(
    () => {
      if (textInput.current) {
        textInput.current.focus();
      }
    }
    , []
  )

  // // push email in existing data
  useEffect(() => {
    setApplication( old => ({...old, email}) )
  }, [email])
  
  function prevRoute(){
    navigate('../preferences')
  }

  function hasError() {
    let isError = false
    if (!isEmail(email)) {
      isError = true
      setErrors("please provide a valid email")
    }
    setErrors("")
    return isError
  }

  // navigate to next route with all application info inside search params
  function nextRoute(){
    const resultsURL = getResultsLink("")
    console.log(resultsURL)
    navigate({pathname: resultsURL.pathname, search: resultsURL.search})
  }

  function getResultsLink(linkSource){
    const updatedApplication = { ...application, source: linkSource};
    const raw_url = window.location.href;
    const link_url = new URL(raw_url.replace("application/submit", "results"));
    link_url.searchParams.set('application', JSON.stringify(updatedApplication));
    return link_url
  }

  // send post request to LUMOS save progress API
  function saveProgress(){    
    setLoading(true)
    const api_url = process.env.REACT_APP_API_URL+'/saveprogress'
    axios.post(api_url, {'email': email, 'url': getResultsLink("email")})
      .then(response => {
        if(response.status === 200){
          setLoading(false)
        }
      })
  }

  return (
      
    <div className="step">

      <div className="title info mt-10 text-center">
        <h2 className="application-title-text">You’re one click away from your personalized recommendations! Time to save your progress.</h2>
      </div>

      {/* email input */}
      <div className="inputs save-progress-email-input my-10">
        <div className="input flex mt-2">
          <input 
            onChange={(e) => setEmail(e.target.value)} 
            value={email} 
            ref={textInput}
            type="email" 
            placeholder="Enter your email address" 
            className="w-full border border-lumos-blue block py-3 px-3 rounded-lg text-center font-semibold"
          />
        </div>
        <div className="input flex">
          <span className="text-red-500 mt-2 block">{errors}</span>
        </div>
      </div>

      {/* actions */}
      <div className="actions save-progress-button-spacing text-center mt-5">

        <button 
          onClick={() => {
            if (hasError()){return}
            saveProgress()
            nextRoute()
          }} 
          className="save-progress-button-next">
            <div className="submit-mobile">
              <span>
              Save My Progress
              </span>
            </div>
            <div className="submit-web">
              <span>
              Save My Progress and Get Recommendations
              </span>
            </div>
          {loading && <span>
            <img className="inline w-8 ml-3" src="/loading.gif" alt=""/>
          </span>}
        </button>

        <a onClick={nextRoute} className="text-lumos-blue mt-8 cursor-pointer skip_to_recommend">
          {"Skip to Recommendations ->"}
        </a>

        {/* <button onClick={prevRoute} className="w-64 p-4 border border-black text-lumos-blue font-semibold text-3xl rounded">
          Back
        </button> */}
        
      </div>
    </div>
  )
}

