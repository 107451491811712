import React, {useContext, useEffect} from "react"
import { ResultsContext } from './Contexts'

import CostComparison2 from "./CostComparison2"
import { useOutletContext } from "react-router-dom"

export default function CostComparisonLayout(props) {

    const {plansToCompare}= useOutletContext()[0]

    return (
        plansToCompare.length > 0 ?
        <div className="mb-20">
            <CostComparison2 planIds={plansToCompare}/>
        </div>
        :   <h2 className="mt-10">Select plans to compare.</h2>
    )
}
