import React, {useState, useEffect, useRef} from 'react'
import resultsLoadingGIF from '../../assets/images/results_loading2.gif'

// intended to hold place while results info is being fetched

export default function LoadingReturnUser(){

  return (
    <div className="title info mt-16 flex flex-col items-center">
      <div className='w-32'>
        <img src={resultsLoadingGIF} alt="loading animation"/>
      </div>
      <h2 className="text-3xl text-lumos-blue text-center font-semibold mt-12">
        Welcome back! Hang on while we load your previous results. 
      </h2>
      <h2 className="text-xl text-lumos-blue text-center mt-4">
        This can take up to a minute...
      </h2>
    </div>
  )
}