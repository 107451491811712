import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useOutletContext, useLocation } from "react-router-dom"

import _ from 'lodash'
import Select from 'react-select'
import { nanoid } from 'nanoid'

import {searchDrugNames, searchDrugStrengths} from '../../utils/apis'
import {getRoute} from './ApplicationLayout'


export default function Drugs(props){
  // When searching for drugs, we do as follows:
  // (1) look for unique name/rxnorm_dose_form in results --> display results
  // (2) 'add' is clicked --> name/rxnomr_dose_form is added to drugs with rxcui/strength
  // (3) upon displaying the drug result element, we do another search with name field as query to get all available dosages.
  // (4) dosage selected ==> update that particular element

  const {application, setApplication} = useOutletContext()
  const location = useLocation()

  const [query, setQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [drugs, setDrugs] = useState(application.drugs || [])

  let navigate = useNavigate()

  // autofocus text input
  const textInput = useRef(null)
  useEffect(
    () => {
      if (textInput.current) {
        textInput.current.focus();
      }
    }
    , []
  )

  // update application if providers ever changes
  useEffect( () =>{
    setApplication((old) => ({...old, drugs}))
  }, [drugs])

  function nextRoute(){
    if (hasError()) {
      return
    }
    navigate(getRoute(application.planYear, location))
  }

  function prevRoute(){
    navigate(getRoute(application.planYear, location, false))
  }


  function hasError(){
    
    let isError = false

    for (const drug of drugs) {
      drug.error = null
      if (!drug.strength) {
        updateDrug( 
          {...drug, error: "select a dosage"}
          , drug
        )
        isError = true
      }
    }
    return isError
  }

  // update a drug
  function updateDrug(attributes, drug) {
    setDrugs( oldDrugs => {
      const idx = _.findIndex(oldDrugs, o => o.elementId === drug.elementId )
      const newDrugs = [...oldDrugs]
      newDrugs[idx] = {...oldDrugs[idx], ...attributes}
      return newDrugs
    })
  }

  // add a drug
  function addDrug(drug){
      setDrugs( old => [...old, {...drug, elementId: nanoid()} ] ) 
      setSearchResults([])
      setQuery("")
  }

  // update drugs to exclude selected drug by rxcui
  function dropDrug(drug){
    setDrugs( old => old.filter( item => (item.elementId !== drug.elementId) ))
  }

  function search(event){
    const newQuery = event.target.value
    setQuery(newQuery)

    // search with debounce, wait 500ms to ensure not called again
    _.debounce( () => {
      searchDrugNames(newQuery)
        .then( res => {
            setSearchResults(res)
          }
        )
    }, 300)()
  }



  // JSX vars
  const resultElems = searchResults.map( (option, index) => 
      <div 
        key={index} 
        className={"drugs-provider-result-spacing result border cursor-pointer relative rounded-lg py-3 border-black border-t-0"}
      >
        <div className="drug-name-text-size flex flex-col">
          <div className="">
            {option.name}:
          </div>
          <div className="font-normal">
            {option.rxnorm_dose_form}
          </div>
        </div>
        <button 
          onClick={() => addDrug(option)} 
          className="drugs-provider-result-add-button text-white bg-lumos-blue rounded text-sm"
        >
          Add
        </button>
      </div>
    ) 
    
    const drugElems = drugs.map( drug => {
      return (
        <Drug
          key = {drug.elementId}
          drug={drug}
          updateDrug={ x => updateDrug(x, drug) }
          dropDrug={ () => dropDrug(drug) }
        />
      )
    }

      
    ) 
      

  return (
    
      <div className="step">

        <div className="mt-10 drugs-search-bar text-center">
          <h2 className="application-title-text">Do you take any prescription drugs?</h2>
          <p className="application-subtitle-text mt-5">We’ll check for plans where these drugs are covered.</p>
        </div>

        {/* search bar */}
        <div className="inputs drugs-search-bar mt-10">
          <div className="input flex mt-2">
            <input 
              value={query} 
              onChange={search} 
              ref={textInput}
              type="text" 
              placeholder="Enter a prescription name" 
              className="w-full border border-lumos-blue block py-3 px-3 rounded-lg"
            />
          </div>
        </div>

        {/* search results */}
        <div className="results drugs-search-bar">
          <div className="scroller border-lumos-blue overflow-y-auto max-h-80">
            {resultElems}
          </div>
        </div>

        {/* show selected drugs, each with its own drop-down menus*/}
        <div className="selected mt-5 drugs-search-bar">
          {drugElems}
          { (drugs.length > 0)?
        <div className="banner border border-black mt-10 bg-lumos-light p-4">
          <b>Disclaimer:</b> We determine whether your selected drugs are covered based on how the insurer reports their coverage status to the Health Insurance Marketplace (Healthcare.gov). However, to be 100% sure that a critical drug is covered by a specific plan, you should contact the insurer directly or review the drug formulary
        </div>
        : <span></span>
      }      
        </div>

        {/* navigation */}
        <div className="actions navigation-spacing mt-16">
          <button onClick={() => prevRoute()} className="navigation-button-back">
            Back
          </button>
          <button 
            onClick={() => nextRoute()} 
            className="navigation-button-next"
          >
            { drugs.length > 0 ? 
              <span>Next</span> 
              : <span>Skip</span>
            }
          </button>
        </div>

      </div>
  )
}

function Drug(props) {
  const { drug, updateDrug, dropDrug } = props

  // use already selected strength if available
  const [strength, setStrength] = useState( drug.rxcui ?
    {rxcui:drug.rxcui, strength:drug.strength} 
    : null
  )

  const [availableStrengths, setAvailableStrengths] = useState(null)

  // request drug strength data only once
  useEffect( () => {
    searchDrugStrengths(drug)
      .then(res => {
        setAvailableStrengths(res)
        if (res.length === 1) {
          console.log('found only one result')
          setStrength(res[0])
        }
      })
    }
    , [] 
  )

  useEffect(
    () => updateDrug(strength)
    , [strength]
  )

  return (
    <div key={"drugs-"+drug.rxcui} className="result mt-2 border relative rounded-lg px-5 py-3 border-black">
      <h2 
        className="drug-name-text-size" 
      >
        {drug.name}: <span className="font-normal">{drug.rxnorm_dose_form}</span>
      </h2>
      {/* cancel button */}
      <span 
        onClick={dropDrug} 
        className="absolute top-1 right-3 text-lg cursor-pointer"
      >
        &times;
      </span>
      
      <div className="details">
        <p className="mt-2 drug-name-text-size font-normal">Dosage:</p>
        <Select
          key={"dosage-"+drug.elementId}
          // defaultValue = { strength ? 
          //   {value: strength, label: strength.strength} 
          //   : null
          // }
          value = {strength ? {value: strength, label: strength.strength} : null}
          className="w-64 mt-2"
          onChange={(e) => {
            setStrength(e.value)
            // updateDrug( e.value )

          }}
          options={availableStrengths ?
            availableStrengths.map(strength => ({value: strength, label: strength.strength}))
          : []
        }
        />
        {/* display error */}
        <div className="errors mt-2">
            <span className="text-red-400">
              {drug.error}
            </span>
        </div>

      </div>

    </div>
    
  )
}
