import React from 'react'
import { useNavigate, useOutletContext, useLocation } from "react-router-dom"

import {getRoute} from './ApplicationLayout'


/**
 * Defines subsidy UI
 * @param {*} props 
 * @returns {JSX}
 */
export default function Subsidy (props) {

  const {application} = useOutletContext()
  const location = useLocation()

  const {is_coverage_gap, is_chip, subsidy, n_plans} = application
  console.log(application)

  const navigate = useNavigate()

  function nextRoute() {
    navigate(getRoute(application.planYear, location))
  }

  function prevRoute() {
    navigate(getRoute(application.planYear, location, false))
  }

  return (
    
    <div className="step px-5">

      { is_coverage_gap &&
        // display medicaid gap info
        <div className="mt-10 text-center">
          <h2 className="application-title-text">You may be in the Medicaid gap</h2>
          <h3 className="application-subtitle-text mt-6">Based on your income, you may not qualify for any savings and your state may not cover you through Medicaid. Learn more about the Medicaid gap <a href="https://www.healthinsurance.org/faqs/what-is-the-medicaid-coverage-gap-and-who-does-it-affect/" className="underline">here</a> </h3>
          <div className="banner text-left border border-black mt-10 bg-lumos-light p-5 mx-4">
            <h2 className="font-bold text-xl">Your options:</h2>
            <h3 className="font-bold text-lg mt-5">Fill out an application through the Marketplace:</h3>
            <p>By providing more detailed information, you may find that you are eligible for Medicaid or if your income is higher, then you may be eligible for subsidies. Create an account and apply directly <a href="https://www.healthcare.gov/create-account" className="underline">here</a></p>

            <h3 className="font-bold text-lg mt-5">Continue with our tool to see recommended plans at <span className="underline">full</span> price</h3>
            <p>You can shop for full price plans by clicking Continue below</p>

            <h3 className="font-bold text-lg mt-5">Shop for lower priced “Catastrophic” plans</h3>
            <p>Catastrophic plans are lower cost but only protect you in worse-case scenarios. You need to pay for most routine medical expenses out-of-pocket but you’ll have access to some preventative services and a few free doctor visits. Learn more about how to qualify and apply for these plans <a href="https://www.healthcare.gov/health-coverage-exemptions/forms-how-to-apply/" className="underline">here</a></p>

            <h3 className="font-bold text-lg mt-5">Find and use low-cost / no-cost health centers</h3>
            <p>Free clinics and federally funded community health centers provide wide range of healthcare services, offering care on a sliding fee scale. Find a health center near you <a href="https://findahealthcenter.hrsa.gov/" className="underline">here</a></p>
          </div>
        </div>
      }

    {/* if CHIP eligible */}
    { !is_coverage_gap && is_chip && 
      <div className="title info mt-10 text-center">
        <h2 className="application-title-text ">You may be eligible for Medicaid and/or CHIP</h2>
        <div className="banner border border-black mt-6 bg-lumos-light p-5 mx-4">
          <span className="font-bold">
          Medicaid is a low-cost (or sometimes free) state-administered health insurance plan available to people with low incomes or certain qualifying medical conditions
          </span>
          <h2 className="mt-10 font-thin text-lg">
            Learn more about Medicaid and how to apply using this healthcare.gov <a className="underline" href="https://www.healthcare.gov/medicaid-chip/getting-medicaid-chip/">link</a> 
          </h2>
        </div>
      </div>
    }

    {/* display no subsidy results */}
    { !is_coverage_gap && !is_chip && !subsidy &&
      <div className="title info mt-10 text-center">
        <h2 className="text-3xl text-lumos-blue font-semibold">You do not qualify for additional savings. However, you are still eligible to enroll in one of {n_plans} plans.</h2>
        <p className="text-lg mt-8">You may not qualify for subsidies either because your income is too high or because eligibility through other means disqualifies you from receiving subsidies</p>
      </div>
    }

    {/* display subsidy results */}
    { (!is_coverage_gap && !is_chip && subsidy) ?
        <>
        <div className="title info mt-10 text-center">
          <h2 className="application-title-text">Good News! You are eligible for {n_plans} plans and up to ${subsidy} in monthly subsidies!</h2>
        </div>
        <div className="savings rounded-lg border border-lumos-blue py-8 px-12 mt-10">
          <p className="subsidy-output-text">You may save up to this much on your premium:</p>
          <h1 className="subsidy-amount-output-text mt-10">${subsidy} / month</h1>
          <p className="subsidy-output-text mt-10">Note: This is an estimate. We'll take this into account when calculating the cost of each plan, but you’ll need to finish enrolling to ensure these savings are final.</p>
        </div>
      </>
      : <></>
    }

    {/* navigation */}
    <div className="actions navigation-spacing mt-20">
      <button 
        onClick={prevRoute} 
        className="navigation-button-back"
      >
        Back
      </button>
        <button onClick={() => nextRoute(navigate)} className="navigation-button-next">
          Continue
        </button>
    </div>

  </div>

  )
}
