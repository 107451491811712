import classNames from 'classnames'
import info from '../../assets/icons/info.svg'
import React, { useEffect, useState } from 'react'
import { useOutletContext, useNavigate, useLocation } from "react-router-dom"

import {getRoute} from "./ApplicationLayout"

import {nanoid} from 'nanoid'

/**
 * Collects demographic info on all family members applying for coverage.
 * @param {*} props
 * @returns
 */
export default function Applicants(props){

  const {application, setApplication} = useOutletContext()
  const location = useLocation()

  const [primary, setPrimary] = useState( application.primary || {})
  const [spouse, setSpouse] = useState( application.spouse || null)
  const [dependents, setDependents] = useState( application.dependents || [])

  const [errors, setErrors] = useState( {} )



  let navigate = useNavigate()

  useEffect( () =>
    setApplication( old => ({...old, primary, spouse, dependents}))
  , [primary, spouse, dependents]
  )

  function checkAge(age, type){
    if (age === "") {
      return "enter a valid age"
    }

    const ageVal = parseInt(age)
    if ( ["primary","spouse"].includes(type) && ageVal >= 65) {
      return "You may be eligible for Medicare. Contact us directly"
    }

    // if (type === "primary" && ageVal < 18) {
    //   return "must be 18+"
    // }

    if (type === 'dependent' && ageVal > 25) {
      return "must be 25 or younger"
    }

    return null
  }

  function checkSex(sex, type){
    if (['M','F'].includes(sex)) {
      return null
    } else {
      return "please indicate sex"
    }

  }

  function hasError() {
    const newErrors = {}
    newErrors['primary'] = {age:checkAge(primary.age, "primary"), sex:checkSex(primary.sex)}
    if (spouse) {
      newErrors['spouse'] = {age:checkAge(spouse.age, "spouse"), sex:checkSex(spouse.sex)}
    }
    if (dependents) {
      newErrors['dependents'] = dependents.map( p => ({age:checkAge(p.age, "dependent"), sex:checkSex(p.sex)}))
    }

    setErrors(newErrors)

    let out = false
    const fields = ['age','sex']

    const people = ['primary']
    if (spouse){
      people.push('spouse')
    }

    for (const key of people){
      for (const field of fields){
        if (newErrors[key][field]){
          out = true
          break
        }
      }
    }
    for (const errorObj of newErrors.dependents){
      for (const field of fields){
        if (errorObj[field]){
          out = true
          break
        }
      }
    }

    return out
  }

  function nextRoute(){

    if (hasError()) {
      return
    }
    navigate(getRoute(application.planYear, location))
  }

  function prevRoute(){
    navigate(getRoute(application.planYear, location, false))
  }



  return (
    <div className="step applicants-component max-w-2xl mx-auto">

      <div className="title info mt-10 text-center">
        <h2 className="application-title-text">Who is applying for coverage?</h2>
        <p className="application-subtitle-text mt-6">We use this to find which plans you are eligible for, and how much they will cost.</p>
      </div>

      <Applicant
        applicant={primary}
        setApplicant= {setPrimary}
        err= {errors.primary}
        type="primary"
        id="primary"
      />

      {/* Spouse details */}
      { spouse ?
        <Applicant
          applicant={spouse}
          setApplicant={setSpouse}
          removeApplicant={() => setSpouse(null)}
          type="spouse"
          err= {errors.spouse}
          id="spouse"
        />
        :
        <div
          onClick={() => setSpouse({sex: (primary.sex === 'M' ? 'F' : (primary.sex === 'F' ? 'M' : '') )}) }
          className="add-spouse rounded-lg mt-4 border border-lumos-blue p-3 text-center hover:bg-lumos-blue hover:text-white hover:cursor-pointer text-lumos-blue">
          <p className="text-lg font-semibold">Add my spouse</p>
        </div>
      }

      { /* dependents */}

      {dependents.map( (applicant, index) =>
        <Applicant
        applicant={applicant}
        setApplicant= { (details) =>
          setDependents( (oldDependents) => {
            const newDependents = [...oldDependents]
            newDependents[index] = details
            return newDependents
          })
        }
        removeApplicant={() => {
          setDependents( (oldDependents) => oldDependents.filter(dependent => dependent.id !== applicant.id))}
        }
        err= {errors && errors.dependents && errors.dependents[index]}
        type="dependent"
        key={applicant.id}
        id = {applicant.id}
        />
      )}

      <div
        onClick={() => {
          const newId = nanoid()
          setDependents( oldDependents => [...oldDependents, {id:newId}] )
        }}

        className="add-spouse rounded-lg mt-4 border border-lumos-blue p-3 text-center hover:bg-lumos-blue hover:text-white hover:cursor-pointer text-lumos-blue"
      >
        <p className="text-lg font-semibold">Add a dependent</p>
      </div>




      {/* form navigation */}
      <div className="actions navigation-spacing mt-12">
        <button onClick={prevRoute} className="navigation-button-back">
          Back
        </button>
        <button onClick={nextRoute} className="navigation-button-next">
          Next
        </button>
      </div>

    </div>
  )
}


function Applicant(props) {

  const {application, setApplication} = useOutletContext()

  // const calculateAge = (birthdate) => {
  //
  //   let today = new Date();
  //   let coverageStart;
  //   let birthDate = new Date(birthdate);
  //
  //   //if getting insurance for same year as plan year, coverage starts 1st of following month
  //   if (parseInt(application.planYear) === today.getFullYear()) {
  //     coverageStart = new Date(today.getFullYear(), today.getMonth()+1, 1);
  //   } else {
  //     //if open enrollment, then enroll for following year, coverage starts 1st of Jan of following year
  //     coverageStart = new Date(today.getFullYear()+1, 0, 1);
  //   }
  //   let age = coverageStart.getFullYear() - birthDate.getFullYear();
  //   const monthDifference = coverageStart.getMonth() - birthDate.getMonth();
  //   if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
  //     age-=1;
  //   }
  //
  //   return age;
  // };

  const {applicant, setApplicant, err = {}, type, id, removeApplicant = null} = props

  // const [dob, setDOB] = useState( (applicant && applicant.dob) || '')
  const [age, setAge] = useState( (applicant && applicant.age) || '')
  const [sex, setSex] = useState( (applicant && applicant.sex) || '')
  const [tobacco, setTobacco] = useState((applicant && applicant.tobacco) || false)
  // minimum eligible coverage
  const [mec, setMec] = useState( (applicant && applicant.eligible) ||false)
  const [pregnant, setPregnant] = useState( (applicant && applicant.pregnant) ||false)

  // pass update up whenever application vars change
  useEffect( () => setApplicant( {...applicant, age, sex, tobacco, mec, pregnant})
    , [age, sex, tobacco, mec, pregnant]
  )

   // Get class name based on if matching sex is selected
  function sexInputClass(sexOption, custom = ''){
    return classNames('px-10 py-2.5 rounded-lg w-36 ' + custom, {
      'text-white bg-lumos-blue z-10': sex === sexOption,
      'text-black bg-white border border-lumos-blue': sex !== sexOption
    })
  }

  // const ageInput_jsx =
  // <div className="age">

  //   <label htmlFor="age" className="font-semibold text-lg align-middle">DOB</label>
  //   <input
  //     type="text"
  //     placeholder="mm/dd/yyyy"
  //     onChange={handleDOBChange}
  //     value={dob}
  //     className="border w-30 border-lumos-blue block py-2 px-3 mt-2 rounded-lg"
  //   />
  //   {/* {age && <p>Age: {age}</p>} */}
  //   {err &&
  //     <span className="text-red-500 mt-2 block ml-1">{err}</span>
  //   }
  // </div>

  const ageInput_jsx =
    <div className="age">

      <label htmlFor="age" className="applicants-inputs-text">Age</label>

      <div className="tooltip-container ml-2 align-middle">
          <div className="tooltip">
              <span className="ml-auto">
                <img width="80%" src={info} alt="Info"/>
              </span>
              <i className="icon ri-arrow-right-line" />
              <span className="tooltip-drop tooltip-right">
                Your age when coverage starts.
              </span>
          </div>
      </div>

      <input
        type="text"
        onChange={(e) => {
          const value = e.target.value;
          const numOnly = parseInt(value.replace(/\D/g, ''))
          setAge(numOnly)
        }}
        value={age}
        className="border w-28 border-lumos-blue block py-2 px-3 mt-2 rounded-lg"
      />
      {err.age &&
        <span className="text-red-500 mt-2 block ml-1">{err.age}</span>
      }
    </div>

  const sexInput_jsx =
    <div className="sex">
      <label htmlFor="age" className="applicants-inputs-text">Sex</label>
      <div className="sex switch flex mt-2">
        <button
          onClick={() => {
            setSex("M")
            setPregnant(false)
            }
          }
          className={sexInputClass('M')}
        >
          Male
        </button>
        <button onClick={() => setSex("F")} className={sexInputClass('F', '-ml-2')}>Female</button>
      </div>
      {err.sex &&
        <span className="text-red-500 mt-2 block ml-1">{err.sex}</span>
      }
    </div>


  return (

    <div className="about-you rounded-lg mt-8 border border-lumos-blue p-5">
      <div className="flex justify-between">
        <h2 className="applicants-inputs-text">
          {type === "primary" && 'About You'}
          {type === "spouse" && 'About Your Spouse'}
          {type === "dependent" && 'About Your Dependent'}
        </h2>
        {// for removing applicant
          type !== 'primary' &&
          <span
            onClick={ removeApplicant }
            className="cursor-pointer text-2xl"
          >
            &times;
          </span>
        }
      </div>
      <div className="inputs applicants-age-sex">
        <div className="applicants-age-sex-input-box">
        {ageInput_jsx}
        </div>
        <div className="applicants-age-sex-input-box">
          {sexInput_jsx}
        </div>
      </div>

      {/* tick box questions */}
      <div className="mt-5 mb-2">
        <p className="applicants-inputs-text">Select all that apply</p>

        <Checkbox
          fieldName= {"tobacco"+ id}
          value= { tobacco }
          setter= {setTobacco}
          optionText= "Tobacco User"
          tooltipText= {<>Applies if <b>you've used tobacco 4 or more times per week for the past 6 months.</b> <br></br><br></br>You may pay more in premiums as a result, but if are a tobacco user, fail to say so, and then develop a tobacco-related illness, your claims may be denied.</>}
        />

        <Checkbox
          fieldName= {"mec" + id}
          value= {mec}
          setter= {setMec}
          optionText= "Eligible for coverage through a job, Medicaid, or CHIP"
          tooltipText= {
            <>
              If this applies, this person will be ineligible for subsidies. Do not select if any of the following is true about your job-based insurance:
              <br></br><br></br>
              <ul className='list-disc ml-2'>
                  <li>Offered COBRA but didn't enroll in it.</li>
                  <li>
                    Not affordable to insure just yourself.
                  </li>
                  <li>Offered insurance through the job of a household member (but not own job) yet it is not affordable to insure the entire family.</li>
              </ul>
              <br></br>

              <em>Coverage is affordable if the lowest cost plan is less than <b>8.39%</b> of household income.</em>
              <br></br><br></br>
              
              Note: If offered an ICHRA, compare to the lowest cost silver plan after applying employer funds.
              
            </>
          }
        />


        {/* pregnant checkbox, displays only if F is currently selected */}
        { sex === 'F' &&
          <Checkbox
            fieldName= {"pregnant" + id}
            value= {pregnant}
            setter= {setPregnant}
            optionText= "Pregnant"
            tooltipText= {<>You can't be denied coverage nor will your premiums be affected if you are pregnant.</>}
          />
        }
      </div>
    </div>
  )

}

function Checkbox(props) {

  const { fieldName, setter, optionText, tooltipText } = props
  const [value, setValue] = useState(props.value)

  useEffect( () =>
    setter(value)
    , [value]
  )



  return (
    <label
      htmlFor={fieldName}
      className={classNames(
        'input cursor-pointer flex items-center p-3 mt-2 rounded-lg border border-lumos-blue hover:bg-lumos-light',
        { 'bg-lumos-light': value }
      )}
    >
      <input
        id={fieldName}
        type="checkbox"
        checked={value}
        name={fieldName}
        onChange={(e) => {
          setValue((old) => !old)
        }}
      />
      <label htmlFor={fieldName} className="ml-2 cursor-pointer w-full">
        {optionText}
      </label>

      {/* tooltip */}
      <div className="tooltip-container ml-auto">
        <div className="tooltip">
          <span className="ml-auto">
            <img src={info} alt="Info" />
          </span>
          <i className="icon ri-arrow-right-line" />
          <span className="tooltip-drop tooltip-left">{tooltipText}</span>
        </div>
      </div>
    </label>
  );
}