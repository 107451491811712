// utility.js

import classNames from 'classnames'

const unsupportedStates = ["CA", "CO", "CT", "DC", "ID", "KY", "ME", "MD", "MA", "MN", "NV", "NJ", "NM", "NY", "PA", "RI", "VT", "VA", "WA"]
const supportedStates = ["TX", "FL", "NC", "GA", "AZ", "MO", "UT"]

export function getStateSupportType(state){
    if(unsupportedStates.includes(state)){
      return 'unsupported'
    }
    else if(supportedStates.includes(state)){
      return 'supported'
    }
    return 'partial'
}


export function getNetPremium(premium, subsidy){
    // why is epsilon necessary
    // consider using num.toFixed(2)
    return Math.round((Math.max(0, premium - subsidy) + Number.EPSILON) * 100) / 100
}

export function getLogo(name){
    let url = "/logos/"+name
    return url+".png"
}

export function metalClass(option, custom = ''){
    return classNames('metal-level flex items-center' + custom, {
        'text-red-600': option === "Catastrophic",
        'text-bronze': option === "Bronze",
        'text-silver': option === "Silver",
        'text-gold': option === "Gold",
        'text-platinum': option === "Platinum",
    })
}


export function commaFormat(x) {
    // returns string with commas every 10e3
    const rounded = Math.round(x)
    return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// sets calss for radio button style options
export function optionClass(option, state, custom = '') {
    return classNames('cursor-pointer px-4 rounded py-3 ' + custom, {
      'border': state !== option,
      'bg-lumos-blue text-white': state === option
    })
}

export function isEmail(email) {
    const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    return emailFormat.test(email)
  }

  export function getCurrentDate(separator='/'){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${month<10?`0${month}`:`${month}`}${separator}${date}${separator}${year}`
    }
