import React, { useEffect, useState } from 'react'
import { useOutletContext, useNavigate, useLocation } from "react-router-dom"

import {getRoute} from './ApplicationLayout'

import Select from 'react-select'

const options = {
  'emergencyFund': [
    {'value': 2000,'label':'~$2,000'}
    , {'value': 5000,'label':'~$5,000'}
    , {'value': 10000,'label':'~$10,000'}
    , {'value': 20000,'label':'$20,000+'}
  ]
  , 'pcpCostTolerance': [
    {'value':30, 'label':'$30'}
    , {'value':50, 'label':'$50'}
    , {'value':100, 'label':'$100'}
    , {'value':200, 'label':'$200'}
    , {'value':-1, 'label':'full cost'}
  ]
}




export default function Preferences(props){

  const {application, setApplication} = useOutletContext()
  const location = useLocation()

  const [errors, setErrors] = useState({emergencyFund:null, pcpCostTolerance:null})
  const [emergencyFund, setEmergencyFund] = useState(
    application.emergencyFund || null
  )
  const [pcpCostTolerance, setPcpCostTolerance] = useState(
    application.pcpCostTolerance || null
  )
  let navigate = useNavigate()

  // pass up inputs
  useEffect(
    () => setApplication( old => ({...old, emergencyFund, pcpCostTolerance}) )
    , [emergencyFund, pcpCostTolerance]
  )

  function hasError(){
    let isError = false
    const newErrors = {}
    if (!emergencyFund) {
      newErrors.emergencyFund = "please provide this info"
      isError = true
    }
    if (!pcpCostTolerance) {
      newErrors.pcpCostTolerance = "please provide this info"
      isError = true
    }
    setErrors(newErrors)
    return isError
  }

  function nextRoute(){
    if (hasError()){
      return
    }
    navigate(getRoute(application.planYear, location))
  }
  
  function prevRoute(){
    navigate(getRoute(application.planYear, location, false))
  }


  // JSX vars
  const emergencyFund_jsx = 
    <>
      <div className="px-4 mt-10">
        <p className="preferences-inputs-text">In a worst-case scenario, how much could you pay for medical expenses in a year without having to borrow money?</p>
      </div>
      
      <Select
        defaultValue={ emergencyFund ? 
          options.emergencyFund.find(item => (item.value === emergencyFund) )
          : null
        }
        onChange={(option) => setEmergencyFund(option.value)}
        className="preferences-selector"
        options={options.emergencyFund}
      />
      <div className="input flex px-4">
        <span className="text-red-500 mt-2 block ml-1">{errors.emergencyFund}</span>
      </div>
      
      {/* explanation */}
      <div className="banner border border-black mt-6 bg-lumos-light p-5 mx-4">
        <b>Why this matters?</b> Plans with high deductibles and high out-of-pocket maximums can be less expensive but often require you to pay for a larger share of your medical bills. In a worst-case scenario, if you don’t have the money to pay for necessary medical expenses, you may need to borrow at high interest rates. We consider this potentially added cost in our recommendations.
      </div>
      </>

    const pcpTolerance_jsx = 
      <>
        <div className="px-4 mt-10">
          <p className="preferences-inputs-text">What’s the most you would be willing to pay for a doctor's visit before reconsidering seeking treatment?</p>
        </div>

        <Select
          defaultValue={ pcpCostTolerance ? 
            options.pcpCostTolerance.find(item => (item.value === pcpCostTolerance) )
            : null
          }
          onChange={(option) => setPcpCostTolerance(option.value)}
          className="preferences-selector"
          options={options.pcpCostTolerance}
        />
        <div className="input flex px-4">
          <span className="text-red-500 mt-2 block ml-1">{errors.pcpCostTolerance}</span>
        </div>
        
        {/* explanation */}
        <div className="banner border border-black mt-6 bg-lumos-light p-5 mx-4">
          <b>Why this matters?</b> Certain plans may be cheaper but at the same time require you to pay a large share of the cost of a doctor's visit.  If you are unable / unwilling to pay a high fee to see your doctor, you may avoid getting much needed care. 
        </div>
      </>


  return (
  

      <div className="step preferences-component">

        <div className="title info mt-10 text-center">
          <h2 className="application-title-text">Last Step! Answer these final two questions before getting your personalized recommendations.</h2>
        </div>

        {emergencyFund_jsx}
        {pcpTolerance_jsx}
        
        {/* navigation buttons */}
        <div className="actions flex items-center justify-center mt-14">
          <button onClick={prevRoute} className="navigation-button-back">
            Back
          </button>
          <button onClick={nextRoute} className="navigation-button-next">
            Next
          </button>
        </div>

      </div>
  )
}

