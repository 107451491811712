import _ from 'lodash'
import React, { useEffect, useState, useRef } from 'react'
import { useOutletContext, useNavigate, useLocation } from "react-router-dom"

import {searchProviders} from '../../utils/apis'
import {getRoute} from './ApplicationLayout'


export default function Providers(props){

  const {application, setApplication} = useOutletContext()
  const location = useLocation()

  const [query, setQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [providers, setProviders] = useState(application.providers || [])
  
  let navigate = useNavigate()

  // update application if providers ever changes
  useEffect( () =>{
    setApplication((old) => ({...old, providers}))
  }, [providers])

  // autofocus text input
  const textInput = useRef(null)
  useEffect(
    () => {
      if (textInput.current) {
        textInput.current.focus();
      }
    }
    , []
  )

  function nextRoute(){
    // hasError()
    navigate(getRoute(application.planYear, location))
  }

  function prevRoute(){
    navigate(getRoute(application.planYear, location, false))
  }


  function addProvider(p){
    setProviders( old => [...old, p])
    setSearchResults([])
    setQuery("")
  }

  function dropProvider(p){
    setProviders( old => old.filter( item => (item.provider.npi !== p.provider.npi) ))
  }

  function search(event){
    const newQuery = event.target.value
    setQuery(newQuery)

    // search with debounce, wait 500ms to ensure not called again
    _.debounce( () => {
      searchProviders(newQuery, application.location.zipcode)
        .then( res => {
            setSearchResults(res)
          }
        )
    }, 300)()
  }

  // JSX vars
  const resultElems = searchResults.map((option, index) => 
    <div 
      key={"drug-options-"+index} 
      className={"result border cursor-pointer relative rounded-lg py-3 border-black border-t-0 drugs-provider-result-spacing"}
    >
      <div className="option-content flex flex-col">
        <h2 className="text-base font-semibold">
          {option.provider.name} 
          <span className="text-sm font-normal"> {option.provider.taxonomy}</span>
        </h2>
        <div className="capitalize text-sm">
          <span>{option.address.street1.toLowerCase()}, </span>
          <span>{option.address.city.toLowerCase()}, </span>
          <span>{option.address.state}</span>  
        </div>
      </div>
      <button 
        onClick={() => {addProvider(option)}} 
        className="drugs-provider-result-add-button text-white bg-lumos-blue rounded text-sm"
      >
        Add
      </button>
    </div>
  ) 

  const providerElems = providers.map((option, index) => 
    <div 
      key={"selected-"+index} 
      className="result mt-2 border relative rounded-lg px-5 py-3 border-black"
    >
      <h2 className="text-base capitalize font-semibold">
        {option.provider.name.toLowerCase()} 
      </h2>
      <span 
        onClick={(e) => dropProvider(option)}
        className="absolute top-1 right-3 text-lg cursor-pointer"
      >
        {/* this is just the 'x' cancel icon */}
        &times;
      </span>
    </div>
  )


  return (
    <div className="step">

      {/* top info */}
      <div className="mt-10 text-center">
        <h2 className="application-title-text">Let’s understand your coverage needs. <br/> Which  providers would you like to be covered?</h2>
        <p className="application-subtitle-text mt-8">We’ll check for plans where they are covered.</p>
      </div>

      {/* search bar */}
      <div className="inputs providers-search-bar mt-10">
        <div className="input flex mt-2">
          <input 
            value={query} 
            onChange={(event) => search(event)} 
            ref={textInput}
            type="text" 
            placeholder="Enter the name of a doctor,  hospital, or pharmacy." 
            className="w-full border border-lumos-blue block py-3 px-3 rounded-lg"/>
        </div>
      </div>

      
      {/* search results */}
      <div className="results providers-search-bar">
        <div className="scroller border-lumos-blue overflow-y-auto max-h-80">
          {resultElems}
        </div>
      </div>

      {/* selected items */}
      <div className="selected providers-search-bar mt-5">
        {providerElems}
        {/* disclaimer */}
      { (providers && providers.length)?
        <div className="banner border border-black mt-10 bg-lumos-light p-4">
          <b>Disclaimer:</b> We determine whether your selected providers are covered based on how they are reported to the Health Insurance Marketplace (Healthcare.gov) by the insurer. However, contracts between providers and insurers can change, which may result in providers falling out of or coming into network. You should always confirm directly with the provider whether a certain plan is covered
        </div>
        : <span></span>
      } 
      </div>

      {/* navigation */}
      <div className="actions navigation-spacing mt-16">
        <button onClick={() => prevRoute(navigate)} className="navigation-button-back">
          Back
        </button>
        <button onClick={() => {
            nextRoute(navigate)
          }} className="navigation-button-next">
          { (providers && providers.length)?
            <span>Next</span>
            : <span>Skip</span>
          }
        </button>
      </div>
      
    </div>
  )
}


